// import React, { useEffect, useRef } from "react";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import Lenis from "@studio-freight/lenis";
// import "./Gsap.css";

// gsap.registerPlugin(ScrollTrigger);

// const ScrollAnimation = () => {
//   const wrapperRef = useRef(null);
//   const section1Ref = useRef(null);
//   const section2Ref = useRef(null);
//   const section3Ref = useRef(null);
//   const content1Ref = useRef(null);
//   const content2Ref = useRef(null);
//   const content3Ref = useRef(null);

//   useEffect(() => {
//     const lenis = new Lenis({
//       duration: 1.2,
//       easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
//       smooth: true,
//       direction: "vertical",
//       smoothTouch: false,
//     });

//     function raf(time) {
//       lenis.raf(time);
//       requestAnimationFrame(raf);
//     }

//     requestAnimationFrame(raf);

//     const tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: wrapperRef.current,
//         start: "top top",
//         end: "+=300%",
//         scrub: 0.25,
//         pin: true,
//       },
//     });

//     tl.to(content1Ref.current, {
//       scale: 10,
//       ease: "none",
//       duration: 1,
//     })
//       .to(section2Ref.current, { autoAlpha: 1, duration: 0.01 })
//       .to(content2Ref.current, {
//         opacity: 1,
//         scale: 1,
//       })
//       .to(content2Ref.current, {
//         scale: 2,
//         ease: "none",
//         duration: 0.5,
//       })
//       .to(section3Ref.current, { autoAlpha: 1, duration: 0.01 })
//       .to(content3Ref.current, {
//         opacity: 1,
//         scale: 1.3,
//         duration: 1,
//       });

//     return () => {
//       ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
//       lenis.destroy();
//     };
//   }, []);

//   return (
//     <>
//       <div className="wrapper" ref={wrapperRef}>
//         <section className="section1" ref={section1Ref}>
//           <div className="content-1" ref={content1Ref}>
//             <p
//               style={{
          
//                 fontSize: "3.5vw",
//                 fontWeight: "600",
//                 width: "70%",
//                 textAlign: "center",
//               }}
//             >
//               You’ve caught a glimpse of our world, but there’s so much more to
//               explore! Our approach to solving business problems isn’t
//               one-size-fits-all; it’s a versatile toolkit filled with different
//               superpowers, or as we call them…
//             </p>
//           </div>
//         </section>
//         <section className="section2" ref={section2Ref}>
//           <div className="content-2 content" ref={content2Ref}>
//             <p
//               style={{
//                 color: "white",
//                 fontSize: "5vw",
//                 fontWeight: "400",
//                 width: "70%",
//                 textAlign: "center",
//               }}
//             >
//               STUDIOS
//             </p>
//           </div>
//         </section>
//         <section className="section3" ref={section3Ref}>
//           <div className="content-3 content" ref={content3Ref}>
//             <p
//               style={{
//                 color: "#3e3e3e",
//                 fontSize: "3.5vw",
//                 fontWeight:"600",
//                 width: "70%",
//                 textAlign: "center",
//               }}
//             >
//               Each studio is unique, where expertise goes beyond technical
//               skills. It’s a fusion led by a Studio Partner, who brings not only
//               technical prowess but also a deep understanding of business
//               implications. This unique blend enables us to deliver solutions
//               that exceed expectations.
//             </p>
//           </div>
//         </section>
      
//       </div>
//     </>
//   );
// };

// export default ScrollAnimation;




// import React, { useEffect, useRef,useState } from "react";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import Lenis from "@studio-freight/lenis";
// import "./Gsap.css";

// gsap.registerPlugin(ScrollTrigger);

// const ScrollAnimation = () => {
//   const wrapperRef = useRef(null);
//   const section1Ref = useRef(null);
//   const section2Ref = useRef(null);
//   const section3Ref = useRef(null);
//   const content1Ref = useRef(null);
//   const content2Ref = useRef(null);
//   const content3Ref = useRef(null);

//   useEffect(() => {
//     const lenis = new Lenis({
//       duration: 1.2,
//       easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
//       smooth: true,
//       direction: "vertical",
//       smoothTouch: false,
//     });

//     function raf(time) {
//       lenis.raf(time);
//       requestAnimationFrame(raf);
//     }

//     requestAnimationFrame(raf);

//     const colors = [
//       "rgba(249, 89, 51, 0.1)",
//       "rgba(100, 65, 160, 0.1)",
//       "rgba(125, 200, 92, 0.1)",
//       "rgba(0, 174, 207, 0.1)",
//       "rgba(0, 0, 0, 0.1)"
//     ];

//     const tl = gsap.timeline({
//       scrollTrigger: {
//         trigger: wrapperRef.current,
//         start: "top top",
//         end: "+=300%",
//         scrub: 0.25,
//         pin: true,
//         anticipatePin: 1,
//       },
//     });

//     colors.forEach((color, index) => {
//       tl.to(section1Ref.current, {
//         backgroundColor: color,
//         duration: 2,
//         ease: "power2.inOut"
//       }, `-=${index === 0 ? 0 : 1.5}`)
//       .to(content1Ref.current, {
//         scale: (index + 1) * 2,
//         duration: 2,
//         ease: "power2.inOut",
//       }, `-=${index === 0 ? 0 : 2}`);
//     });

//     tl.to(section2Ref.current, { autoAlpha: 1, duration: 0.01 })
//       .to(content2Ref.current, {
//         opacity: 1,
//         scale: 1,
//       })
//       .to(content2Ref.current, {
//         scale: 2,
//         ease: "power2.inOut",
//         duration: 1,
//       })
//       .to(section3Ref.current, { autoAlpha: 1, duration: 0.01 })
//       .to(content3Ref.current, {
//         opacity: 1,
//         scale: 1.3,
//         duration: 1,
//       });

//     return () => {
//       ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
//       lenis.destroy();
//     };
//   }, []);


//   return (
//     <>
//       <div className="wrapper" ref={wrapperRef}>
//         <section className="section1" ref={section1Ref}>
//           <div className="content-1" ref={content1Ref}
          
      
         
          
          
//           >
//             <p
//               style={{
//                 color: "black",
//                 fontSize: "3.5vw",
//                 fontWeight: "400",
//                 width: "70%",
//                 textAlign: "center",
//               }}
            
//             >
//               You’ve caught a glimpse of our world, but there’s so much more to
//               explore! Our approach to solving business problems isn’t
//               one-size-fits-all; it’s a versatile toolkit filled with different
//               superpowers, or as we call them…
//             </p>
//           </div>
//         </section>
//         <section className="section2" ref={section2Ref}>
//           <div className="content-2 content" ref={content2Ref}>
//             <p
//               style={{
//                 color: "white",
//                 fontSize: "5vw",
//                 fontWeight: "400",
//                 width: "70%",
//                 textAlign: "center",
//               }}
//             >
//               STUDIOS
//             </p>
//           </div>
//         </section>
//         <section className="section3" ref={section3Ref}>
//           <div className="content-3 content" ref={content3Ref}>
//             <p
//               style={{
//                 color: "black",
//                 fontSize: "3.5vw",
//                 width: "70%",
//                 textAlign: "center",
//               }}
//             >
//               Each studio is unique, where expertise goes beyond technical
//               skills. It’s a fusion led by a Studio Partner, who brings not only
//               technical prowess but also a deep understanding of business
//               implications. This unique blend enables us to deliver solutions
//               that exceed expectations.
//             </p>
//           </div>
//         </section>
//       </div>
      
//     </>
//   );
// };

// export default ScrollAnimation;


// Zoom out

import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from "@studio-freight/lenis";
import "./Gsap.css";

gsap.registerPlugin(ScrollTrigger);

const ScrollAnimation = () => {
  const wrapperRef = useRef(null);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const content1Ref = useRef(null);
  const content2Ref = useRef(null);
  const content3Ref = useRef(null);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      smooth: true,
      direction: "vertical",
      smoothTouch: false,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: "top 70",
        end: "+=300%",
        scrub: 0.25,
        pin: true,
      },
    });

    // Reverse the animation to zoom out
    tl.to(content1Ref.current, {
      scale: 0.1, // Start from a larger scale and zoom out
      ease: "none",
      duration: 1,
    })
      .to(section2Ref.current, { autoAlpha: 1, duration: 0.01 })
      .to(content2Ref.current, {
        opacity: 1,
        scale: 1, // Start at a smaller scale
      })
      .to(content2Ref.current, {
        scale: 0.1, // Zoom out further
        ease: "none",
        duration: 1,
      })
      .to(section3Ref.current, { autoAlpha: 1, duration: 0.01 })
      .to(content3Ref.current, {
        opacity: 1,
        scale: 0.9, // Zoom out
        duration: 1,
      });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      lenis.destroy();
    };
  }, []);

  return (
    <>
      <div className="wrapper" ref={wrapperRef}>
        <section className="section1" ref={section1Ref}>
          <div className="content-14" ref={content1Ref}>
            <p
              style={{
                fontSize: "3.5vw",
                fontWeight: "600",
                width: "70%",
                textAlign: "center",
              }}
            >
              You’ve caught a glimpse of our world, but there’s so much more to
              explore! Our approach to solving business problems isn’t
              one-size-fits-all; it’s a versatile toolkit filled with different
              superpowers, or as we call them…
            </p>
          </div>
        </section>
        <section className="section2" ref={section2Ref} >
          <div className="content-24 content" ref={content2Ref}>
            <p
              style={{
                textShadow:"2px 2px 4px rgba(0, 0, 0, 0.4);",
                color: "white",
                fontSize: "10vw",
                fontWeight: "400",
                width: "70%",
                textAlign: "center",
              
              }}
            >
              STUDIOS
            </p>
          </div>
        </section>
        <section className="section3" ref={section3Ref}>
          <div className="content-34 content" ref={content3Ref}>
            <p
              style={{
                color: "#3e3e3e",
                fontSize: "3.5vw",
                fontWeight: "600",
                width: "70%",
                textAlign: "center",
              }}
            >
              Each studio is unique, where expertise goes beyond technical
              skills. It’s a fusion led by a Studio Partner, who brings not only
              technical prowess but also a deep understanding of business
              implications. This unique blend enables us to deliver solutions
              that exceed expectations.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default ScrollAnimation;
