// import React,{useEffect} from "react";
// import "./XD.css";
// import OtherStudios from "../../components/Cards/mainCards"
// import XdHero from "../../assets/xd3.png";
// import Connect from "../../components/ConnectWithUs/ConnectWithUs";
// import Xd1 from "../../assets/xdTest.gif"
// import Xd2 from "../../assets/StudiosGifs/XD-2.gif"
// import Xd3 from "../../assets/StudiosGifs/XD-3.gif"
// import { MdOutlineArrowBackIos } from "react-icons/md";
// import { Link } from "react-router-dom";

// function XdPage() {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);
//   return (
//     <>
//       <div className="xd-hero-container">
//         <div className="xd-hero-img-cc">
//           <img src={XdHero} alt="Xd hero" />

//           <div className="xd-hero-txt-cnt">
//             <Link to="/studios" className="head-back-btn">
//               <MdOutlineArrowBackIos className="back-icon-btn" />
//               <h5>EXPERIENCE DESIGN STUDIO</h5>
//             </Link>

//             <h3>
//               Unlock the Power of Connected Systems & Data with Expert
//               Integration & Analytics
//             </h3>
//             <p>
//               Masters of user-centric design, we craft intuitive and engaging
//               experiences that delight users. Our focus on seamless interactions
//               ensures every touchpoint is both functional and enjoyable, setting
//               us apart in creating memorable digital experiences.
//             </p>
//           </div>
//         </div>
//       </div>
//       <div className="card-st-container">
//         <div className="card-sd-item">
//           <div className="text-cnt-sd">
//             <h2>Intuitive Designs</h2>
//             <h3>WHAT WE DO.</h3>
//             {/* <p>
//             We create designs that are easy to understand, easy to use, and
//             intuitive. Our goal is to make complex concepts accessible and engaging
//             for users.
//           </p> */}
//             <p>
//               Every design decision is focused on improving the user experience.
//               Engage your audience with visually appealing and user-friendly
//               interfaces. We push the boundaries to create designs that stand
//               out in the digital realm.
//             </p>
//           </div>
//           <div className="card-item-img-sd">
//             <img src={Xd1} alt="" />
//           </div>
//         </div>
//         <div className="card-sd-item" style={{ flexDirection: "row-reverse" }}>
//           <div className="text-cnt-sd">
//             <h2>Innovation in Design </h2>
//             <h3>HOW WE DO IT.</h3>
//             <p>
//               Our approach entails pushing the frontiers of design, ensuring
//               that every decision is anchored in improving user experience.
//               Every project is designed with creativity and individuality in
//               mind.
//             </p>
//           </div>
//           <div className="card-item-img-sd">
//             <img src={Xd2} alt="" />
//           </div>
//         </div>

//         <div className="card-sd-item">
//           <div className="text-cnt-sd">
//             <h2>Impactful Digital Presence </h2>
//             <h3>FINAL PRODUCT</h3>
//             <p>
//               The end result is a visually appealing and intuitive design that
//               improves your brand's digital presence and leaves a lasting impact
//               on your target audience.
//             </p>
//           </div>
//           <div className="card-item-img-sd">
//             <img src={Xd3} alt="" />
//           </div>
//         </div>
//       </div>

//       <OtherStudios/>
 
//       <Connect />
//     </>
//   );
// }

// export default XdPage;


import React, { useEffect } from 'react';
import './XD.css';
import OtherStudios from '../../components/Cards/mainCards';
import XdHero from '../../assets/xd3.webp';
import Connect from '../../components/ConnectWithUs/ConnectWithUs';
import Xd1 from '../../assets/StudiosGifs/XD-1.gif';
import Xd2 from '../../assets/StudiosGifs/XD-2.gif';
import Xd3 from '../../assets/StudiosGifs/XD-3.gif';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import useScrollReveal from './Animation';

const revealVariants = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
};

function XdPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ref1, controls1] = useScrollReveal({ triggerOnce: false, threshold: 0.1 });
  const [ref2, controls2] = useScrollReveal({ triggerOnce: false, threshold: 0.1 });
  const [ref3, controls3] = useScrollReveal({ triggerOnce: false, threshold: 0.1 });

  return (
    <>
      <div className="xd-hero-container">
        <div className="xd-hero-img-cc">
          <img src={XdHero} alt="Xd hero" />
          <div className="xd-hero-txt-cnt">
            <Link to="/studios" className="head-back-btn">
              <MdOutlineArrowBackIos className="back-icon-btn" />
              <h5>EXPERIENCE DESIGN STUDIO</h5>
            </Link>
            <h3>
              Unlock the Power of Connected Systems & Data with Expert Integration & Analytics
            </h3>
            <p>
              Masters of user-centric design, we craft intuitive and engaging experiences that delight users. Our focus on seamless interactions ensures every touchpoint is both functional and enjoyable, setting us apart in creating memorable digital experiences.
            </p>
          </div>
        </div>
      </div>
      <div className="card-st-container">
        <motion.div
          className="card-sd-item"
          ref={ref1}
          initial="hidden"
          animate={controls1}
          variants={revealVariants}
        >
          <div className="text-cnt-sd">
            <h2>Intuitive Designs</h2>
            <h3>WHAT WE DO.</h3>
            <p>
              Every design decision is focused on improving the user experience. Engage your audience with visually appealing and user-friendly interfaces. We push the boundaries to create designs that stand out in the digital realm.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={Xd1} alt="" />
          </div>
        </motion.div>

        <motion.div
          className="card-sd-item mb-reverse"
          ref={ref2}
          initial="hidden"
          animate={controls2}
          variants={revealVariants}
          // style={{ flexDirection: 'row-reverse' }}
        >
          <div className="text-cnt-sd">
            <h2>Innovation in Design </h2>
            <h3>HOW WE DO IT.</h3>
            <p>
              Our approach entails pushing the frontiers of design, ensuring that every decision is anchored in improving user experience. Every project is designed with creativity and individuality in mind.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={Xd2} alt="" />
          </div>
        </motion.div>

        <motion.div
          className="card-sd-item"
          ref={ref3}
          initial="hidden"
          animate={controls3}
          variants={revealVariants}
        >
          <div className="text-cnt-sd">
            <h2>Impactful Digital Presence </h2>
            <h3>FINAL PRODUCT</h3>
            <p>
              The end result is a visually appealing and intuitive design that improves your brand's digital presence and leaves a lasting impact on your target audience.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={Xd3} alt="" />
          </div>
        </motion.div>
      </div>

      <OtherStudios />
      <Connect />
    </>
  );
}

export default XdPage;
