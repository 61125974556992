// import React from 'react';
// import "./cards.css";
// import XD from "../../assets/xd@2x.png";
// import CA from "../../assets/ca@2x1.png";
// import WE from "../../assets/w3@2x.png";
// import W3 from "../../assets/w-1@2x.png";
// import DC from "../../assets/dc@2x.png";
// import {Link, useLocation} from "react-router-dom"
// import ClientLogos from '../ClientLogos/ClientLogos';

// function MainCards() {

//   const location = useLocation();
//   const isInExperienceDesignPage = location.pathname === "/experience-design";
//   const isInCAPage = location.pathname === "/cloud-applications";
//   const isInWEPage = location.pathname === "/workforce-engagement";
//   const isInW3Page = location.pathname === "/web-3";
//   const isInDcPage = location.pathname === "/data-centric";
//   const isTitleInStudioPage = location.pathname === "/studios";

//  return (
//   <>
//   {(!isTitleInStudioPage&&
//   <div className='studios-main-head-title'> <h1> Explore Our Studios</h1></div>)}
//     <div className='studios-section-home'>
//     {!isInExperienceDesignPage && (
//       <Link to="/experience-design" className='studio-card bg-orange'>
//         <div className='studio-image'>
//           <img src={XD} alt='Studio 1' />
//         </div>
//         <div className='studio-info'>
//           <h3>XD Studio </h3>
//           <hr className='line-cards' />
//           <p>Pioneers at the forefront of Web3 technologies, building the future of decentralized applications.  <span className='read-more-std'>Read more...</span></p>
//         </div>
//       </Link>
//     )}

// {!isInCAPage && (
//       <Link to="/cloud-applications" className='studio-card bg-purple' >
//         <div className='studio-image' style={{backgroundColor:"#6441A0"}}>
//           <img src={CA} alt='Studio 1' />
//         </div>
//         <div className='studio-info'>
//           <h3>CA Studio </h3>
//           <hr className='line-cards' />
//           <p>Skilled developers specializing in crafting the best flows and business processes for SaaS Applications <span className='read-more-std'>Read more...</span></p>
       
//         </div>
//       </Link>
// )}

// {( !isInWEPage &&
//       <Link to="/workforce-engagement" className='studio-card bg-green'>
//         <div className='studio-image'>
//           <img src={WE} alt='Studio 1' />
//         </div>
//         <div className='studio-info'>
//           <h3>WE Studio </h3>
//           <hr className='line-cards' />
//           <p>Skilled developers specializing in crafting the best flows and business processes for SaaS Applications  <span className='read-more-std'>Read more...</span></p>
        
//         </div>
//       </Link>
//       )}


// {(!isInW3Page &&
//       <Link to="/web-3" className='studio-card bg-blue'>
//         <div className='studio-image'>
//           <img src={W3} alt='Studio 1' />
//         </div>
//         <div className='studio-info'>
//           <h3>W3 Studio </h3>
//           <hr className='line-cards' />
//           <p>Pioneers at the forefront of Web3 technologies, building the future of decentralized applications.<span className='read-more-std'>Read more...</span></p>
         
//         </div>
//       </Link>

// )}

// {(!isInDcPage &&
//       <Link to="/data-centric" className='studio-card bg-black'>
//         <div className='studio-image'>
//           <img src={DC} alt='Studio 1' />
//         </div>
//         <div className='studio-info'>
//           <h3>DC Studio </h3>
//           <hr className='line-cards' />
//           <p>We specialize in empowering you to unlock valuable insights hidden within your data, driving informed decisions.  <span className='read-more-std'>Read more...</span></p>
       
//         </div>
//       </Link>
// )}

//     </div>
  
//    </>    
//   );
// }

// export default MainCards;


// import React from 'react';
// import { motion } from 'framer-motion';
// import './cards.css';
// import XD from "../../assets/xd@2x.png";
// import CA from "../../assets/ca@2x1.png";
// import WE from "../../assets/w3@2x.png";
// import W3 from "../../assets/w-1@2x.png";
// import DC from "../../assets/dc@2x.png";
// import { Link, useLocation } from "react-router-dom";

// function MainCards() {
//   const location = useLocation();
//   const isInExperienceDesignPage = location.pathname === "/experience-design";
//   const isInCAPage = location.pathname === "/cloud-applications";
//   const isInWEPage = location.pathname === "/workforce-engagement";
//   const isInW3Page = location.pathname === "/web-3";
//   const isInDcPage = location.pathname === "/data-centric";
//   const isTitleInStudioPage = location.pathname === "/studios";

//   const flipAnimation = {
//     hidden: { rotateY: -90, opacity: 0 },
//     visible: {
//       rotateY: 0,
//       opacity: 1,
//       transition: { duration: 1.2, ease: 'easeOut' },
//     },
//   };

//   return (
//     <>
//       {!isTitleInStudioPage && (
//         <div className='studios-main-head-title'>
//           <h1>Explore Our Studios</h1>
//         </div>
//       )}
//       <div className='studios-section-home'>
//         {!isInExperienceDesignPage && (
//           <motion.div
//             className='studio-card bg-orange'
//             initial="hidden"
//             animate="visible"
//             variants={flipAnimation}
//           >
//             <Link to="/experience-design">
//               <div className='studio-image'>
//                 <img src={XD} alt='Studio 1' />
//               </div>
//               <div className='studio-info'>
//                 <h3>XD Studio</h3>
//                 <hr className='line-cards' />
//                 <p>Pioneers at the forefront of Web3 technologies, building the future of decentralized applications. <span className='read-more-std'>Read more...</span></p>
//               </div>
//             </Link>
//           </motion.div>
//         )}

//         {!isInCAPage && (
//           <motion.div
//             className='studio-card bg-purple'
//             initial="hidden"
//             animate="visible"
//             variants={flipAnimation}
//           >
//             <Link to="/cloud-applications">
//               <div className='studio-image'>
//                 <img src={CA} alt='Studio 1' />
//               </div>
//               <div className='studio-info'>
//                 <h3>CA Studio</h3>
//                 <hr className='line-cards' />
//                 <p>Skilled developers specializing in crafting the best flows and business processes for SaaS Applications <span className='read-more-std'>Read more...</span></p>
//               </div>
//             </Link>
//           </motion.div>
//         )}

//         {!isInWEPage && (
//           <motion.div
//             className='studio-card bg-green'
//             initial="hidden"
//             animate="visible"
//             variants={flipAnimation}
//           >
//             <Link to="/workforce-engagement">
//               <div className='studio-image'>
//                 <img src={WE} alt='Studio 1' />
//               </div>
//               <div className='studio-info'>
//                 <h3>WE Studio</h3>
//                 <hr className='line-cards' />
//                 <p>Skilled developers specializing in crafting the best flows and business processes for SaaS Applications <span className='read-more-std'>Read more...</span></p>
//               </div>
//             </Link>
//           </motion.div>
//         )}

//         {!isInW3Page && (
//           <motion.div
//             className='studio-card bg-blue'
//             initial="hidden"
//             animate="visible"
//             variants={flipAnimation}
//           >
//             <Link to="/web-3">
//               <div className='studio-image'>
//                 <img src={W3} alt='Studio 1' />
//               </div>
//               <div className='studio-info'>
//                 <h3>W3 Studio</h3>
//                 <hr className='line-cards' />
//                 <p>Pioneers at the forefront of Web3 technologies, building the future of decentralized applications. <span className='read-more-std'>Read more...</span></p>
//               </div>
//             </Link>
//           </motion.div>
//         )}

//         {!isInDcPage && (
//           <motion.div
//             className='studio-card bg-black'
//             initial="hidden"
//             animate="visible"
//             variants={flipAnimation}
//           >
//             <Link to="/data-centric">
//               <div className='studio-image'>
//                 <img src={DC} alt='Studio 1' />
//               </div>
//               <div className='studio-info'>
//                 <h3>DC Studio</h3>
//                 <hr className='line-cards' />
//                 <p>We specialize in empowering you to unlock valuable insights hidden within your data, driving informed decisions. <span className='read-more-std'>Read more...</span></p>
//               </div>
//             </Link>
//           </motion.div>
//         )}
//       </div>
//     </>
//   );
// }

// export default MainCards;

import React from 'react';
import "./cards.css";
import XD from "../../assets/xd@2x.png";
import CA from "../../assets/ca@2x1.png";
import WE from "../../assets/w3@2x.png";
import W3 from "../../assets/w-1@2x.png";
import DC from "../../assets/dc@2x.png";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function MainCards() {
  const location = useLocation();
  const isInExperienceDesignPage = location.pathname === "/experience-design";
  const isInCAPage = location.pathname === "/cloud-applications";
  const isInWEPage = location.pathname === "/workforce-engagement";
  const isInW3Page = location.pathname === "/web-3";
  const isInDcPage = location.pathname === "/data-centric";
  const isTitleInStudioPage = location.pathname === "/studios";

  // Intersection Observer for each card
  const [xdRef, xdInView] = useInView({ triggerOnce: false });
  const [caRef, caInView] = useInView({ triggerOnce: false });
  const [weRef, weInView] = useInView({ triggerOnce: false });
  const [w3Ref, w3InView] = useInView({ triggerOnce: false });
  const [dcRef, dcInView] = useInView({ triggerOnce: false });

  return (
    <>
      {!isTitleInStudioPage && (
        <div className='studios-main-head-title'>
          <h1>Explore Our Studios</h1>
        </div>
      )}
      <div className='studios-section-home'>
        {!isInExperienceDesignPage && (
          <Link to="/experience-design">
            <motion.div
              ref={xdRef}
              initial={{ rotateY: 180 }}
              animate={{ rotateY: xdInView ? 0 : 180 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className='studio-card bg-orange'
            >
              <div className='studio-image'>
                <img src={XD} alt='Studio 1' />
              </div>
              <div className='studio-info'>
                <h3>XD Studio</h3>
                <hr className='line-cards' />
                <p>Pioneers at the forefront of Web3 technologies, building the future of decentralized applications.  <span className='read-more-std'>Read more...</span></p>
              </div>
            </motion.div>
          </Link>
        )}

        {!isInCAPage && (
          <Link to="/cloud-applications">
            <motion.div
              ref={caRef}
              initial={{ rotateY: 180 }}
              animate={{ rotateY: caInView ? 0 : 180 }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              className='studio-card bg-purple'
            >
              <div className='studio-image'>
                <img src={CA} alt='Studio 2' />
              </div>
              <div className='studio-info'>
                <h3>CA Studio</h3>
                <hr className='line-cards' />
                <p>Skilled developers specializing in crafting the best flows and business processes for SaaS Applications <span className='read-more-std'>Read more...</span></p>
              </div>
            </motion.div>
          </Link>
        )}

        {!isInWEPage && (
          <Link to="/workforce-engagement">
            <motion.div
              ref={weRef}
              initial={{ rotateY: 180 }}
              animate={{ rotateY: weInView ? 0 : 180 }}
              transition={{ duration: 1, ease: "easeInOut" }}
              className='studio-card bg-green'
            >
              <div className='studio-image'>
                <img src={WE} alt='Studio 3' />
              </div>
              <div className='studio-info'>
                <h3>WE Studio</h3>
                <hr className='line-cards' />
                <p>Skilled developers specializing in crafting the best flows and business processes for SaaS Applications <span className='read-more-std'>Read more...</span></p>
              </div>
            </motion.div>
          </Link>
        )}

        {!isInW3Page && (
          <Link to="/web-3">
            <motion.div
              ref={w3Ref}
              initial={{ rotateY: 180 }}
              animate={{ rotateY: w3InView ? 0 : 180 }}
              transition={{ duration: 1.2, ease: "easeInOut" }}
              className='studio-card bg-blue'
            >
              <div className='studio-image'>
                <img src={W3} alt='Studio 4' />
              </div>
              <div className='studio-info'>
                <h3>W3 Studio</h3>
                <hr className='line-cards' />
                <p>Pioneers at the forefront of Web3 technologies, building the future of decentralized applications.<span className='read-more-std'>Read more...</span></p>
              </div>
            </motion.div>
          </Link>
        )}

        {!isInDcPage && (
          <Link to="/data-centric">
            <motion.div
              ref={dcRef}
              initial={{ rotateY: 180 }}
              animate={{ rotateY: dcInView ? 0 : 180 }}
              transition={{ duration: 1.4, ease: "easeInOut" }}
              className='studio-card bg-black'
            >
              <div className='studio-image'>
                <img src={DC} alt='Studio 5' />
              </div>
              <div className='studio-info'>
                <h3>DC Studio</h3>
                <hr className='line-cards' />
                <p>We specialize in empowering you to unlock valuable insights hidden within your data, driving informed decisions.<span className='read-more-std'>Read more...</span></p>
              </div>
            </motion.div>
          </Link>
        )}
      </div>
    </>
  );
}

export default MainCards;
