import React, { useEffect } from "react";
import "../XD/XD.css";
import CaHero from "../../assets/weHero.webp";
import OtherStudios from "../../components/Cards/mainCards";
import ConnectWithUs from "../../components/ConnectWithUs/ConnectWithUs";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import WeGif1 from "../../assets/StudiosGifs/WE-1.gif";
import WeGif2 from "../../assets/StudiosGifs/WE-2.gif";
import WeGif3 from "../../assets/StudiosGifs/WE-3.gif";
import useScrollReveal from "../XD/Animation";

function Wepage() {
  const revealVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ref1, controls1] = useScrollReveal({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [ref2, controls2] = useScrollReveal({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [ref3, controls3] = useScrollReveal({
    triggerOnce: false,
    threshold: 0.1,
  });
  return (
    <>
      <div className="xd-hero-container">
        <div className="xd-hero-img-cc">
          <img src={CaHero} alt="Xd hero" />

          <div className="xd-hero-txt-cnt">
            <Link to="/studios" className="head-back-btn">
              <MdOutlineArrowBackIos className="back-icon-btn" />
              <h5>WORKFORCE ENGAGEMENT STUDIO</h5>
            </Link>

            <h3>
              Unlock the Power of Connected Systems & Data with Expert
              Integration & Analytics
            </h3>
            <p>
              Experts in SaaS application Implementation and customization, we
              tailor software solutions to fit your unique business processes
              and workflows. Our bespoke approach ensures seamless integration
              and optimization, enhancing efficiency and aligning perfectly with
              your operational needs.
            </p>
          </div>
        </div>
      </div>

      <div className="card-st-container">


        <motion.div className="card-sd-item"
        ref={ref1}
        initial="hidden"
        animate={controls1}
        variants={revealVariants}>
          <div className="text-cnt-sd">
            <h2 style={{ color: "#7DC85C" }}>Team Collaboration Solutions </h2>
            <h3>WHAT WE DO</h3>

            <p>
              Encourage teamwork with technologies that allow for seamless
              collaboration. Provide your employees with the resources and
              assistance they need to succeed in their jobs. End-to-end
              integration solution for custom workforce management tools such as
              Oracle HCM, Salesforce, SAP, Workday, ADP, and many more.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={WeGif1} alt="" />
          </div>
        </motion.div>


        <motion.div
          className="card-sd-item mb-reverse"
          ref={ref2}
          initial="hidden"
          animate={controls2}
          variants={revealVariants}
          // style={{ flexDirection: 'row-reverse' }}
        >
          <div className="text-cnt-sd">
            <h2 style={{ color: "#7DC85C" }}>Tailored Workforce Management</h2>
            <h3>HOW WE DO IT</h3>
            <p>
              Our strategy entails providing end-to-end solutions that are
              suited to your workforce requirements. We effortlessly integrate
              custom tools, increasing cooperation and productivity.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={WeGif2} alt="" />
          </div>
        </motion.div>

        <motion.div className="card-sd-item"
        ref={ref3}
        initial="hidden"
        animate={controls3}
        variants={revealVariants}>
          <div className="text-cnt-sd">
            <h2 style={{ color: "#7DC85C" }}>Inspired Workplace Experience </h2>
            <h3>FINAL PRODUCT</h3>
            <p>
              The end result is an inspired working experience in which teams
              collaborate seamlessly, increasing productivity and creating an
              environment in which all team members thrive.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={WeGif3} alt="" />
          </div>
          </motion.div>


      </div>

      <OtherStudios />
      <ConnectWithUs />
    </>
  );
}

export default Wepage;
