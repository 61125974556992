import React from 'react'
import "./BackendOfferings.css"
import PythonLogo from "../../../assets/python-logo-05.png"
import NodeLogo from "../../../assets/nodejs-logo-05.png"


function BackendOfferings() {
  return (
    <>
    <div className='BackEndTech-Bg'>
      <div className='BET-Tech-left'>
        <img src={PythonLogo} alt=""/>
      </div>
      <div className='BET-Tech-right'>
        <p>
          Powerful frameworks for feature-rich solutions
          </p>
          <p>
          While your app’s frontend is the face you present to your audience, the
          backend is what keeps them coming back. Leveraging Python’s powerful
          frameworks and exhaustive libraries, we unlock for you the most suitable
          application development, integration and data analytics.
          </p>
          <p>
          Through Supernal Infotech’s Python backend app development, you can
          enjoy customised back-ends capable of offering highly interactive, feature-
          rich and superlatively scalable web applications. Add to it our suite of
          Python-based data analytics and aggregation tools and you will never face a
          hitch while managing your audience.
          </p>
          <p>

          Combining Python’s high extensibility with Supernal’s expertise in app
          development, we bring you the best of both worlds, with services such as
          custom software development, upgrading existing apps, seamless migration
          to python-based applications, data exchange and consistent support.
                
        </p>
      </div>
    </div>
{/* ------------------------------------------------------ */}
{/* ------------------------------------------------------ */}

<div className='BackEndTech-Bg-nodejs'>
      <div className='BET-Tech-right-nodejs'>
         <p>
         Capable run-time environment for custom applications
          </p>
          <p>
          Supernal Infotech is a frontrunner in the popular Node.js back-end
          development tool, and are ready to partner with you for immaculate full-
          cycle services. Our experienced developers have spent countless hours
          honing their Node.js skills and can offer you a host of services, from
          application and product development to technology consulting as well as
          support for migration and app maintenance.
          </p>
          <p>
          We also bring to the table proven knowledge of launching cloud-based and
          server-less applications focused on unique and asynchronous programming,
          powerful performance and high scalability. Contact us for building REST
          (Representational State Transfer) and JSON (JavaScript Object Notation)
          APIs capable of streaming data and services aimed at unlocking enhanced
          security, lite processes and efficient user experience.
          </p>
          <p>
          With agile app development processes in place, we bring you a one-stop
          shop for Node.js services such as Custom Application Development,
          Migration and end-to-end Product Development.
                
        </p>
      </div>
      <div className='BET-Tech-left'>
        <img src={NodeLogo} alt="nodejs logo"/>
      </div>

    </div>


    </>
  )
}

export default BackendOfferings