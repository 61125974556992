import React, { useEffect } from "react";
import ClientLogos from "../components/ClientLogos/ClientLogos";
import Hero from "../components/Hero/Hero";
import "./Home.css";
import Footer from "../components/Footer/Footer";
import Studios from "../components/StudiosCardsHome/StudioCard";
import KeyTwo from "../components/KeyTwo/KeyTwo";
import ConnectWithUs from "../components/ConnectWithUs/ConnectWithUs";
import Banking from "./Industries/Banking/Banking";
import Carousel from "../components/CarouselHome/CarouselHome.jsx";
import KeyThree from "../components/KeyThree/KeyThree.jsx";
import KeyFour from "../components/KeyFour/KeyFour.jsx";
import GsapItem from "../components/CarouselHome/Gsap.jsx";
import Articles from "../components/HomeArticles/HomeArticles.jsx";
import MobileHomePage from "../components/CarouselHome/MobileHomePage/MobileHomePage.jsx";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="Home-main-container">
        {/* <Banking/> */}
        <Hero />
        <div className="display-mobile-items">
          <MobileHomePage />
        </div>
        {/* <Studios /> */}
        {/* <KeyTwo /> */}
        <div className="display-mobile">
          <GsapItem />
        </div>

        <div className="display-mobile">
          <Carousel />
        </div>

        {/* <KeyThree /> */}
        <KeyFour />
        <Articles />
        <ConnectWithUs />
        <div className="bg-cli-logo">
          <ClientLogos />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Home;
