// src/Carousel.js
import React from "react";
import "./slider.css";
import Client1 from "../../../assets/DCSlider/4sy2Xi.svg";
import Client2 from "../../../assets/DCSlider/Artwork 7.svg";
import Client3 from "../../../assets/DCSlider/Asset 15.svg";
import Client4 from "../../../assets/DCSlider/Asset 17.svg";
import Client5 from "../../../assets/DCSlider/Asset 18.svg";
import Client6 from "../../../assets/DCSlider/D8pzci.svg";
import Client7 from "../../../assets/DCSlider/E8s9OQ.tif.svg";
import Client8 from "../../../assets/DCSlider/Qgq7iT.tif.svg";
import Client9 from "../../../assets/DCSlider/sdfvrv.svg";


const images = [
  Client1,
  Client2,
  Client3,
  Client4,
  Client5,
  Client6,
  Client7,
  Client8,
  Client9,
];

const Carousel = () => {
  return (
    <>
     
      <div className="wrapper-client">
        {images.map((image, index) => (
          <div className={`item-client item-client${index + 1}`} key={index}>
            <img
              className="item-img-client-dc"
              src={image}
              alt={`Client ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Carousel;
