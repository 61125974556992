// src/Carousel.js
import React from "react";
import "./ClientLogos.css";
import Client1 from "../../assets/clients_logos/Aizatron.svg";
import Client2 from "../../assets/clients_logos/careedge.svg";
import Client3 from "../../assets/clients_logos/criticalriver.svg";
import Client4 from "../../assets/clients_logos/gspann.svg";
import Client5 from "../../assets/clients_logos/hexaware.svg";
import Client6 from "../../assets/clients_logos/innova.svg";
import Client7 from "../../assets/clients_logos/paysitters.svg";
import Client8 from "../../assets/clients_logos/tigeranalytics.svg";
import Client9 from "../../assets/clients_logos/waferwire.svg";

const images = [
  Client1,
  Client2,
  Client3,
  Client4,
  Client5,
  Client6,
  Client7,
  Client8,
  Client9,
];

const Carousel = () => {
  return (
    <>
      <div className="client-logos-title">
        <h1>Trusted by Great Companies</h1>
      </div>
      <div className="wrapper-client">
        {images.map((image, index) => (
          <div className={`item-client item-client${index + 1}`} key={index}>
            <img
              className="item-img-client"
              src={image}
              alt={`Client ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Carousel;
