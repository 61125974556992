import React,{useEffect} from "react";
import "./AboutUs.css";
import Map from "./Map/Map";
import CoreTwo from "./CoreTwo/CoreTwo";
import AboutImg from "../../assets/aboutUs.webp"
import CoreThree from "./CoreThree/CoreThree"

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div classNameName="about-us-page">
        <div className="header-about">
          <div className="container111">
            <img src={AboutImg} alt=""/>
<div className="about-us-inner-text">
            <p className="header__blurb">
              Founded in 2020, Supernal Infotech Pvt Ltd has grown from a small
              startup into a leading provider of technology solutions. Our
              journey reflects a dedication to innovation and a commitment to
              our clients' success. From the beginning, we've embraced
              cutting-edge technologies and built a diverse team of experts to
              deliver exceptional value.<br/>
              Our mission is to be the trusted partner for companies,
                collaborating on innovative solutions that address their
                business challenges from ideation through implementation to
                ongoing success.
            </p>
            </div>
          
        

            {/* </div> */}
           
            {/* <div className="header__tiles">
              <h2> Mission</h2>
              <p>
                Our mission is to be the trusted partner for companies,
                collaborating on innovative solutions that address their
                business challenges from ideation through implementation to
                ongoing success.
              </p>
            </div> */}
          </div>
        </div>

        {/* <section className="section"></section> */}
  

       
      </div>
      <Map/>

      {/* <CoreTwo/> */}
      <CoreThree/>
    </>
  );
}

export default AboutUs;
