// import React from "react";
// import "./MobileHomePage.css";
// import XD from "../../../assets/StudiosHeroImages/XD83@2x.png";
// import CA from "../../../assets/StudiosHeroImages/CA.png";
// import WE from "../../../assets/StudiosHeroImages/WE86@2x.png";
// import W3 from "../../../assets/StudiosHeroImages/W387@2x.png";
// import DC from "../../../assets/StudiosHeroImages/DC84@2x.png";
// import { Link } from "react-router-dom";

// function MobileHomePage() {
//   return (
//     <>
//       <div className="mobile-gsap-home">
//         <div className="mob-text-intro">
//           <p>
//             You’ve caught a glimpse of our world, but there’s so much more to
//             explore! Our approach to solving business problems isn’t
//             one-size-fits-all; it’s a versatile toolkit filled with different
//             superpowers, or as we call them…
//           </p>
//         </div>
//         <div className="mob-text-intro">
//           <p
//             style={{
//               fontSize: "8vw",
//               fontWeight:"800"
//             }}
//           >
//             STUDIOS
//           </p>
//         </div>
//         <div className="mob-text-intro">
//           <p>
//             Each studio is unique, where expertise goes beyond technical skills.
//             It’s a fusion led by a Studio Partner, who brings not only technical
//             prowess but also a deep understanding of business implications. This
//             unique blend enables us to deliver solutions that exceed
//             expectations.
//           </p>
//         </div>
//       </div>



      

//       <div className="studios-mobile">
//         <Link to="/experience-design" className="studios-card-item">
//           <div className="studio-card-img">
//             <img src={XD} alt="" />
//           </div>
//           <div className="studio-card-text">
//             <p>
//               As experts in user-centric design, we develop intuitive and
//               engaging experiences that captivate users.<br/> <b style={{color:"#f95933",lineHeight:"2"}}>Read more...</b>
//             </p>
//             <h1 style={{color:"#f95933"}}> Experience Design</h1>
//           </div>
//         </Link>

//         <Link to="/cloud-applications" className="studios-card-item">
//           <div className="studio-card-img">
//             <img src={CA} alt="" />
//           </div>
//           <div className="studio-card-text">
//             <p>
//             Specializing in cloud-native applications and the modernization of applications, we utilize<br/> <b style={{color:"#6441a0",lineHeight:"2"}}>Read more...</b>
//             </p>
//             <h1 style={{color:"#6441a0"}}> Cloud Applications</h1>
//           </div>
//         </Link>

//         <Link to="/workforce-engagement" className="studios-card-item">
//           <div className="studio-card-img">
//             <img src={WE} alt="" />
//           </div>
//           <div className="studio-card-text">
//             <p>
//             Experts in SaaS application implementation and customization, we tailor software solutions <br/> <b style={{color:"#7dc85c",lineHeight:"2"}}>Read more...</b>
//             </p>
//             <h1 style={{color:"#7dc85c"}}> Workforce Engagement</h1>
//           </div>
//         </Link>

//         <Link to="/web-3" className="studios-card-item">
//           <div className="studio-card-img">
//             <img src={W3} alt="" />
//           </div>
//           <div className="studio-card-text">
//             <p>
//             Leading the charge in Web3 development, we integrate AI/ML, IoT, and blockchain technologies<br/> <b style={{color:"#00aecf",lineHeight:"2"}}>Read more...</b>
//             </p>
//             <h1 style={{color:"#00aecf"}}> Web-3</h1>
//           </div>
//         </Link>

//         <Link to="/data-centric" className="studios-card-item">
//           <div className="studio-card-img">
//             <img src={DC} alt="" />
//           </div>
//           <div className="studio-card-text">
//             <p>
//             As leaders in data-centric services, we specialize in business intelligence, analytics, and integration.<br/> <b style={{color:"#000000",lineHeight:"2"}}>Read more...</b>
//             </p>
//             <h1 style={{color:"#000000"}}> Data Centric</h1>
//           </div>
//         </Link>

     

   
//       </div>
//     </>
//   );
// }

// export default MobileHomePage;


import React from "react";
import "./MobileHomePage.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import XD from "../../../assets/StudiosHeroImages/XD83@2x.png";
import CA from "../../../assets/StudiosHeroImages/CA.png";
import WE from "../../../assets/StudiosHeroImages/WE86@2x.png";
import W3 from "../../../assets/StudiosHeroImages/W387@2x.png";
import DC from "../../../assets/StudiosHeroImages/DC84@2x.png";
import { Link } from "react-router-dom";

function MobileHomePage() {
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });
  const [ref5, inView5] = useInView({ triggerOnce: true });
  const [ref6, inView6] = useInView({ triggerOnce: true });
  const [ref7, inView7] = useInView({ triggerOnce: true });
  const [ref8, inView8] = useInView({ triggerOnce: true });
  const [ref9, inView9] = useInView({ triggerOnce: true });
  const [ref10, inView10] = useInView({ triggerOnce: true });

  return (
    <>
      <div className="mobile-gsap-home">
        <motion.div
          ref={ref1}
          className="mob-text-intro"
          initial={{ opacity: 0, y: -50 }}
          animate={inView1 ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
          transition={{ duration: 1.2, ease: "easeInOut" }}
        >
          <p>
            You’ve caught a glimpse of our world, but there’s so much more to
            explore! Our approach to solving business problems isn’t
            one-size-fits-all; it’s a versatile toolkit filled with different
            superpowers, or as we call them…
          </p>
        </motion.div>
        <motion.div
          ref={ref2}
          className="mob-text-intro"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={inView2 ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
          transition={{ duration: 1.2, ease: "easeOut" }}
        >
          <p style={{ fontSize: "8vw", fontWeight: "800" }}>STUDIOS</p>
        </motion.div>
        <motion.div
          ref={ref3}
          className="mob-text-intro"
          initial={{ x: -100, opacity: 0 }}
          animate={inView3 ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
          transition={{ duration: 1.2, ease: "easeInOut" }}
        >
          <p>
            Each studio is unique, where expertise goes beyond technical skills.
            It’s a fusion led by a Studio Partner, who brings not only technical
            prowess but also a deep understanding of business implications. This
            unique blend enables us to deliver solutions that exceed
            expectations.
          </p>
        </motion.div>
      </div>

      <div className="studios-mobile">
        <Link to="/experience-design" className="studios-card-item">
          <motion.div
            ref={ref4}
            className="studio-card-img"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={inView4 ? { scale: 1, opacity: 1 } : { scale: 0.8, opacity: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <img src={XD} alt="Experience Design" />
          </motion.div>
          <motion.div
            ref={ref5}
            className="studio-card-text"
            initial={{ opacity: 0, rotateY: -90 }}
            animate={inView5 ? { opacity: 1, rotateY: 0 } : { opacity: 0, rotateY: -90 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <p>
              As experts in user-centric design, we develop intuitive and
              engaging experiences that captivate users.<br />
              <b style={{ color: "#f95933", lineHeight: "2" }}>Read more...</b>
            </p>
            <h1 style={{ color: "#f95933" }}>Experience Design</h1>
          </motion.div>
        </Link>

        <Link to="/cloud-applications" className="studios-card-item">
          <motion.div
            ref={ref6}
            className="studio-card-img"
            initial={{ y: 100, opacity: 0 }}
            animate={inView6 ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
            transition={{ duration: 1.2, ease: "easeOut" }}
          >
            <img src={CA} alt="Cloud Applications" />
          </motion.div>
          <motion.div
            ref={ref7}
            className="studio-card-text"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={inView7 ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <p>
              Specializing in cloud-native applications and the modernization of
              applications, we utilize<br />
              <b style={{ color: "#6441a0", lineHeight: "2" }}>Read more...</b>
            </p>
            <h1 style={{ color: "#6441a0" }}>Cloud Applications</h1>
          </motion.div>
        </Link>

        <Link to="/workforce-engagement" className="studios-card-item">
          <motion.div
            ref={ref8}
            className="studio-card-img"
            initial={{ x: -100, opacity: 0 }}
            animate={inView8 ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <img src={WE} alt="Workforce Engagement" />
          </motion.div>
          <motion.div
            ref={ref9}
            className="studio-card-text"
            initial={{ opacity: 0, y: 50 }}
            animate={inView9 ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <p>
              Experts in SaaS application implementation and customization, we
              tailor software solutions <br />
              <b style={{ color: "#7dc85c", lineHeight: "2" }}>Read more...</b>
            </p>
            <h1 style={{ color: "#7dc85c" }}>Workforce Engagement</h1>
          </motion.div>
        </Link>

        <Link to="/web-3" className="studios-card-item">
          <motion.div
            ref={ref10}
            className="studio-card-img"
            initial={{ rotateX: -90, opacity: 0 }}
            animate={inView10 ? { rotateX: 0, opacity: 1 } : { rotateX: -90, opacity: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <img src={W3} alt="Web-3" />
          </motion.div>
          <motion.div
            className="studio-card-text"
            initial={{ opacity: 0, rotateY: 90 }}
            animate={inView10 ? { opacity: 1, rotateY: 0 } : { opacity: 0, rotateY: 90 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <p>
              Leading the charge in Web3 development, we integrate AI/ML, IoT,
              and blockchain technologies<br />
              <b style={{ color: "#00aecf", lineHeight: "2" }}>Read more...</b>
            </p>
            <h1 style={{ color: "#00aecf" }}>Web-3</h1>
          </motion.div>
        </Link>

        <Link to="/data-centric" className="studios-card-item">
          <motion.div
            className="studio-card-img"
            initial={{ y: 100, opacity: 0 }}
            animate={inView10 ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <img src={DC} alt="Data Centric" />
          </motion.div>
          <motion.div
            className="studio-card-text"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={inView10 ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
            transition={{ duration: 1.2, ease: "easeInOut" }}
          >
            <p>
              As leaders in data-centric services, we specialize in business
              intelligence, analytics, and integration.<br />
              <b style={{ color: "#000000", lineHeight: "2" }}>Read more...</b>
            </p>
            <h1 style={{ color: "#000000" }}>Data Centric</h1>
          </motion.div>
        </Link>
      </div>
    </>
  );
}

export default MobileHomePage;
