// // IntegrationExpertise.js
// import React from "react";
// import Agri from "../../assets/IndustriesSVG2/agri.svg";
// import Manufacturing from "../../assets/IndustriesSVG2/manufacturing.svg";
// import Healthcare from "../../assets/IndustriesSVG2/health.svg";
// import Fintech from "../../assets/IndustriesSVG2/fintech.svg";
// import Retail from "../../assets/IndustriesSVG2/retail.svg";
// import Education from "../../assets/IndustriesSVG2/edutech.svg";
// import "./Dc.css";

// const IntegrationExpertise = () => {
//   return (
//     <>
//       <div className="dc-main-head-text" style={{ background: "#eaeeef" }}>
//         <h1 style={{ background: "#eaeeef" }}> Our Integration Expertise</h1>
//         <p>
//           We offer a full spectrum of integration services to meet your unique
//           needs
//         </p>
//       </div>

//       <div className="map-video-container-ept">
//         <div className="industries-list-container-ept">
//           {[
//             { img: Agri, title: "Data Integration", desc: "Connect disparate data sources, standardize formats, and break down silos to create a single, unified view of your information." },
//             { img: Manufacturing, title: "API Integration", desc: "Enable seamless communication and data exchange between your applications, fostering automation and collaboration." },
//             { img: Healthcare, title: "Enterprise Integration", desc: "Streamline complex business processes, integrate legacy systems with modern applications, and improve overall agility." },
//             { img: Fintech, title: "Cloud Integration", desc: "Harness the power of the cloud by integrating cloud-based and on-premises applications for greater scalability and flexibility." },
//             { img: Retail, title: "Event-Driven Integration", desc: "Build real-time, responsive systems that react instantly to events and triggers, enabling faster decision-making." },
//             { img: Education, title: "Microservices Integration", desc: "Break down monolithic applications into smaller, independent services for enhanced flexibility and easier maintenance." }
//           ].map((industry, index) => (
//             <div className="industry-item-ept" key={index}>
//               <div className="industry-item-ept-img">
//                 <img src={industry.img} alt={industry.title} />
//               </div>
//               <div className="industry-item-ept-txt">
//                 <h3>{industry.title}</h3>
//                 <p>{industry.desc}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// };

// export default IntegrationExpertise;


// IntegrationExpertise.js
import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Agri from "../../assets/IndustriesSVG2/agri.svg";
import Manufacturing from "../../assets/IndustriesSVG2/manufacturing.svg";
import Healthcare from "../../assets/IndustriesSVG2/health.svg";
import Fintech from "../../assets/IndustriesSVG2/fintech.svg";
import Retail from "../../assets/IndustriesSVG2/retail.svg";
import Education from "../../assets/IndustriesSVG2/edutech.svg";
import "./Dc.css";

// Define the fade-in and scale-up animation variants
const fadeInScaleVariants = {
  
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.8, ease: "easeOut" } }
};

const AnimatedIndustryItem = ({ industry, index }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 0.1
  });

  return (
    <motion.div
      className="industry-item-ept"
      ref={ref}
      variants={fadeInScaleVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="industry-item-ept-img">
        <img src={industry.img} alt={industry.title} />
      </div>
      <div className="industry-item-ept-txt">
        <h3>{industry.title}</h3>
        <p>{industry.desc}</p>
      </div>
    </motion.div>
  );
};

const IntegrationExpertise = () => {
  
  return (
    <>
      <div className="dc-main-head-text" style={{ background: "#eaeeef" }}>
        <h1>Our Integration Expertise</h1>
        <p>We offer a full spectrum of integration services to meet your unique needs</p>
      </div>

      <div className="map-video-container-ept">
        <div className="industries-list-container-ept">
          {[
            { img: Agri, title: "Data Integration", desc: "Connect disparate data sources, standardize formats, and break down silos to create a single, unified view of your information." },
            { img: Manufacturing, title: "API Integration", desc: "Enable seamless communication and data exchange between your applications, fostering automation and collaboration." },
            { img: Healthcare, title: "Enterprise Integration", desc: "Streamline complex business processes, integrate legacy systems with modern applications, and improve overall agility." },
            { img: Fintech, title: "Cloud Integration", desc: "Harness the power of the cloud by integrating cloud-based and on-premises applications for greater scalability and flexibility." },
            { img: Retail, title: "Event-Driven Integration", desc: "Build real-time, responsive systems that react instantly to events and triggers, enabling faster decision-making." },
            { img: Education, title: "Microservices Integration", desc: "Break down monolithic applications into smaller, independent services for enhanced flexibility and easier maintenance." }
          ].map((industry, index) => (
            <AnimatedIndustryItem key={index} industry={industry} index={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default IntegrationExpertise;
