import React from 'react'
import ContactImg from "../../../assets/ContactUs.webp";
import { Link } from 'react-router-dom';

function ShoppingConnectWithUs() {
  return (
    <div className='connect-with-us'>
    <div className='image-container-cc'>
      <img src={ContactImg} alt="Contact Us" />
      <div className='text-overlay-cc'>
        <h2>Your Partner in Commerce Innovation</h2>
       
<p>Supernal Infotech is your trusted partner in the commerce sector. With our proven track record and expertise in cutting-edge technology, we empower you to achieve operational excellence, enhance customer experiences, and stay ahead of the competition.</p>
<Link to="/contact-us">
          <button>Schedule a Consultation</button>
          </Link>
      </div>
    </div>
  </div>
  )
}

export default ShoppingConnectWithUs