import React,{useEffect} from "react";
import "./Dc.css";

import ImplIcon1 from "../../assets/onprem.svg";
import ImplIcon2 from "../../assets/cloud.svg";
import ImplIcon3 from "../../assets/hybrid.svg";
import Slider from "./Slider/Slider";
import Proven from "../../assets/Proven169.png";
import Focused from "../../assets/Focused168.png";
import Inno from "../../assets/Innov167.png";
import End from "../../assets/EndToEnd166.png";
import Vend from "../../assets/Vendor165.png";
import ConnectWithUs from "../../components/ConnectWithUs/ConnectWithUs";
import IntegrationExpertise from "./IntegrationExpertise";
import DcHeroComp from "./DcHero"
import OurBusinessIntelligence from "./OurBusinessIntelligence";
import OtherStudios from "../../components/Cards/mainCards";
import OurAnalyticsMethodology from "./OurAnalyticsMethodology";

function Wepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
     <DcHeroComp/>
      
      <IntegrationExpertise/>

      {/* Implementation Options: */}
      <div className="dc-main-head-text">
        <h1>Implementation Options </h1>
      </div>

      <div className="impl-dc-main-ct">
        <div className="impl-card-items">
          <div className="impl-card-item">
            <div className="impl-img-icon">
              <img src={ImplIcon1} alt="" />
            </div>
            <div className="impl-txt-22">
              <h3> Cloud Solutions</h3>
              <p>
                We leverage leading Integration Platform as a Service (iPaaS)
                solutions, Low-Code/No-Code platforms like MuleSoft, Boomi,
                Workato, Informatica Cloud, and others to build scalable,
                efficient, and easily maintainable integrations.
              </p>
            </div>
          </div>

          <div className="impl-card-item">
            <div className="impl-img-icon">
              <img src={ImplIcon2} alt="" />
            </div>
            <div className="impl-txt-22 impl-txt-23" style={{marginTop:"-1.3vw"}} >
              <h3>On-Prem Solutions</h3>
              <p>
                For organizations with existing on-premises infrastructure, we
                provide integration solutions using proven technologies like
                Oracle SOA, Boomi, Informatica, Talend etc.
              </p>
            </div>
          </div>

          <div className="impl-card-item">
            <div className="impl-img-icon">
              <img src={ImplIcon3} alt="" />
            </div>
            <div className="impl-txt-22">
              <h3>Hybrid Middleware Solutions</h3>

              <p>
                Get the best of both worlds! We can design and implement a
                hybrid middleware architecture that bridges the gap between your
                cloud and on-premise environments.
              </p>
            </div>
          </div>
        </div>
        <div className="impl-right-img"> </div>
      </div>

     


<OurBusinessIntelligence/>

      {/* Our Analytics Methodology */}
<OurAnalyticsMethodology/>
     

      <Slider />

      {/* Why Choose Supernal */}
      <div className="dc-main-head-text">
        <h1>Why Choose Supernal </h1>
      </div>

      <div className="why-choose-cnt">
        <div className="why-choose-item">
          <div className="why-choose-img">
            <img src={Proven} alt="Agri" />
          </div>
          <div className="why-choose-item-text">
            <p>
              Leveraging extensive experience across diverse industries, our
              data engineers deliver custom integration and analytics solutions
              with a proven record of success.
            </p>
          </div>
        </div>

        <div className="why-choose-item">
          <div className="why-choose-img">
            <img src={Focused} alt="Agri" />
          </div>
          <div className="why-choose-item-text">
            <p>
              We provide comprehensive services, encompassing strategic
              planning, seamless implementation, and ongoing support to ensure
              your success.
            </p>
          </div>
        </div>

        <div className="why-choose-item">
          <div className="why-choose-img">
            <img src={Inno} alt="Agri" />
          </div>
          <div className="why-choose-item-text">
            <p>
              Free from vendor lock-in, we recommend best-in-class solutions
              tailored to your specific needs, ensuring an objective approach to
              data mastery.
            </p>
          </div>
        </div>

        <div className="why-choose-item">
          <div className="why-choose-img">
            <img src={End} alt="Agri" />
          </div>
          <div className="why-choose-item-text">
            <p>
              Our data experts stay at the forefront of industry trends and
              technologies, continuously seeking advancements to deliver
              cutting-edge solutions that keep you ahead of the competition.
            </p>
          </div>
        </div>

        <div className="why-choose-item">
          <div className="why-choose-img">
            <img src={Vend} alt="Agri" />
          </div>
          <div className="why-choose-item-text">
            <p>
              We are committed to your strategic goals. By delivering impactful
              data solutions, we measure our success by the value we create for
              your business.
            </p>
          </div>
        </div>
      </div>

      <OtherStudios />

      <ConnectWithUs />
    </>
  );
}

export default Wepage;
