import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./popup.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Popup = ({ handleClose, show }) => {
  const showHideClassName = show ? "modal display-block" : "display-none";

  const form = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_hmr6kt8",
        "template_q4hjasi",
        form.current,
        "EM5itf02kEAs25gIg"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
          setSubmitted(true);
          setTimeout(function () {
            window.location = "/";
          }, 4000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  // Close the popup when clicking outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (show && !e.target.closest(".modal-main")) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [show, handleClose]);


  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <i  onClick={handleClose} className="fa-solid fa-x"></i>
        <h2>Request for Services</h2>
        <p>
          Please inform us about the service you wish to avail by filling out
          the form provided below. We will promptly reach out to you.
        </p>
        <form className="register-form-popup" ref={form} onSubmit={sendEmail}>
          <label htmlFor="firstName">Full Name:</label>
          <input type="text" required id="firstName" name="firstName" />

          <label htmlFor="companyName">Company Name:</label>
          <input type="text" required id="companyName" name="company" />

          <label htmlFor="mobile">Mobile Number:</label>
          <PhoneInput
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            inputStyle={{width: "100%",}}
            country={'in'} // Default country
            value={phoneNumber}
            onChange={setPhoneNumber}
          />

          <label htmlFor="email">Email:</label>
          <input type="email" required id="email" name="email" />
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            placeholder="Please provide additional information so we can better respond to your request"
            name="message"
            rows="4"
            required
          ></textarea>

          <button type="submit">Submit</button>
        </form>
        {submitted && (
          <div className="success-message">
            <p>
              Thank you for submitting the form! <br />
              Your information has been received and our representative will be
              in touch with you soon.
            </p>
          </div>
        )} 
      </section>
    </div>
  );
};

export default Popup;
