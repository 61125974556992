import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import "./WebThreeArt.css";
import { FaWifi, FaChartLine, FaRobot, FaCogs, FaTools } from 'react-icons/fa';
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { Link } from "react-router-dom";
import ContactImg from "../../../assets/ContactUs.webp";

const HeroSection = () => {

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  return (
    <>
      <section className="hero-section-art-h">
        <motion.div
          className="hero-content-art-h"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h1 className="hero-title-art-h">
            Unlocking the Power of IT Services
          </h1>
          <p className="hero-subtitle-art-h">
            How They Propel the Manufacturing Industry into Industry 4.0 and
            Their Game-Changing Benefits
          </p>
          {/* <motion.button
            className="hero-button-art-h"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Learn More
          </motion.button> */}
        </motion.div>
      </section>

      <div className="info-section-art-h">
        <div className="info-content-art-h">
          <h2 className="info-title-art-h">Introduction</h2>
          <p className="info-text-art-h">
            The manufacturing industry is experiencing a revolution known as
            Industry 4.0, where digital technologies are transforming
            traditional processes. This shift promises remarkable gains in
            efficiency, flexibility, and overall performance. At the core of
            this revolution are IT services, which empower manufacturers to
            fully leverage the potential of Industry 4.0. Join us as we delve
            into how IT services are driving this transformation and uncover the
            exciting benefits they bring to the manufacturing world.
          </p>

          <h2 className="info-title-art-h">Understanding Industry 4.0</h2>
          <p className="info-text-art-h">
            Industry 4.0 marks the dawn of the fourth industrial revolution,
            bringing with it a wave of cutting-edge technologies like the
            Internet of Things (IoT), Cyber-Physical Systems (CPS), Big Data and
            Analytics, Artificial Intelligence (AI) and Machine Learning (ML),
            and Cloud Computing. This exciting era is transforming the way we
            work and live!
          </p>
        </div>
      </div>

      <div className="key-components-section-art-h">
        <h2 className="key-components-title-art-h">
          Key Components of Industry 4.0
        </h2>
        <div className="key-components-grid-art-h">
          <motion.div
            className="key-component-card-art-h"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="key-component-title-art-h">IoT & IIoT</h3>
            <p className="key-component-text-art-h">
              Enables machines and devices to communicate and share data.
            </p>
          </motion.div>
          <motion.div
            className="key-component-card-art-h"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="key-component-title-art-h">CPS</h3>
            <p className="key-component-text-art-h">
              Integrates physical processes with digital systems.
            </p>
          </motion.div>
          <motion.div
            className="key-component-card-art-h"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="key-component-title-art-h">
              Big Data and Analytics
            </h3>
            <p className="key-component-text-art-h">
              Provides insights for decision-making.
            </p>
          </motion.div>
          <motion.div
            className="key-component-card-art-h"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="key-component-title-art-h">AI and ML</h3>
            <p className="key-component-text-art-h">
              Automates processes and enhances decision-making.
            </p>
          </motion.div>
          <motion.div
            className="key-component-card-art-h"
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="key-component-title-art-h">Cloud Computing</h3>
            <p className="key-component-text-art-h">
              Facilitates data storage and processing.
            </p>
          </motion.div>
        </div>
      </div>

      <div className="benefits-section-art-h">
        <h2 className="benefits-title-art-h">
          Benefits of Industry 4.0 for Manufacturing
        </h2>
        <div className="benefits-grid-art-h">
          <div className="benefits-column-art-h">
            <div className="benefit-item-art-h">
              <h3 className="benefit-title-art-h">
                Increased Efficiency and Productivity
              </h3>
              <p className="benefit-text-art-h">
                Automation and real-time data analysis streamline operations.
              </p>
            </div>
            <div className="benefit-item-art-h">
              <h3 className="benefit-title-art-h">
                Enhanced Flexibility and Customization
              </h3>
              <p className="benefit-text-art-h">
                Adaptive manufacturing systems respond quickly to market
                changes.
              </p>
            </div>

            <div className="benefit-item-art-h">
              <h3 className="benefit-title-art-h">
                Improved Quality and Reduced Waste
              </h3>
              <p className="benefit-text-art-h">
                Advanced analytics and predictive maintenance minimize defects
                and downtime.
              </p>
            </div>
            <div className="benefit-item-art-h">
              <h3 className="benefit-title-art-h">Better Decision-Making</h3>
              <p className="benefit-text-art-h">
                Data-driven insights lead to informed strategic decisions.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="middle-banner-art-h">
        <div className="middle-banner-content-art-h">
          <h2 className="middle-banner-title-art-h">
            How IT Solutions and Services Propel Companies Toward Industry 4.0
          </h2>
          <div className="middle-banner-points-art-h">
            <div className="middle-banner-point-art-h">
              <h3 className="middle-banner-point-title-art-h">
                IT Infrastructure
              </h3>
              <p className="middle-banner-point-text-art-h">
                IT Solutions and Services are the backbone of Industry 4.0,
                offering essential infrastructure, software solutions, and
                integration capabilities.
              </p>
            </div>
            <div className="middle-banner-point-art-h">
              <h3 className="middle-banner-point-title-art-h">
                Network Connectivity
              </h3>
              <p className="middle-banner-point-text-art-h">
                Reliable, high-speed networks are crucial for seamless
                communication among IoT devices and systems.
              </p>
            </div>
            <div className="middle-banner-point-art-h">
              <h3 className="middle-banner-point-title-art-h">
                Data Storage and Management
              </h3>
              <p className="middle-banner-point-text-art-h">
                Cloud-based solutions provide scalable storage and efficient
                data management.
              </p>
            </div>
            <div className="middle-banner-point-art-h">
              <h3 className="middle-banner-point-title-art-h">Cybersecurity</h3>
              <p className="middle-banner-point-text-art-h">
                Safeguarding sensitive data and systems from cyber threats is
                imperative.
              </p>
            </div>
          </div>
          <h2
            className="middle-banner-title-art-h"
            style={{ fontSize: "1.6vw", marginTop: "5vw", textAlign: "left" }}
          >
            Software Solutions:
          </h2>
          <div className="middle-banner-points-art-h">
            <div className="middle-banner-point-art-h">
              <h3 className="middle-banner-point-title-art-h">ERP Systems</h3>
              <p className="middle-banner-point-text-art-h">
                Integrate various business processes, offering a unified view of
                operations.
              </p>
            </div>
            <div className="middle-banner-point-art-h">
              <h3 className="middle-banner-point-title-art-h">
                MES (Manufacturing Execution Systems)
              </h3>
              <p className="middle-banner-point-text-art-h">
                Monitor and control manufacturing processes in real-time.
              </p>
            </div>
            <div className="middle-banner-point-art-h">
              <h3 className="middle-banner-point-title-art-h">
                SCADA (Supervisory Control and Data Acquisition)
              </h3>
              <p className="middle-banner-point-text-art-h">
                Manage industrial processes through data collection and
                analysis.
              </p>
            </div>
            <div className="middle-banner-point-art-h">
              <h3 className="middle-banner-point-title-art-h">
                Predictive Maintenance Tools
              </h3>
              <p className="middle-banner-point-text-art-h">
                Leverage AI and ML to forecast equipment failures and schedule
                maintenance proactively.
              </p>
            </div>
          </div>

          <h2
            className="middle-banner-title-art-h"
            style={{ fontSize: "1.6vw", marginTop: "5vw", textAlign: "left" }}
          >
            System Integration:{" "}
          </h2>
          <div className="middle-banner-points-art-h">
            <div className="middle-banner-point-art-h">
              <h3 className="middle-banner-point-title-art-h">
                Legacy System Integration:
              </h3>
              <p className="middle-banner-point-text-art-h">
                Ensures that older systems can interface with new digital
                technologies.
              </p>
            </div>
            <div className="middle-banner-point-art-h">
              <h3 className="middle-banner-point-title-art-h">
                Seamless Communication:
              </h3>
              <p className="middle-banner-point-text-art-h">
                Facilitates information flow across different components and
                systems.
              </p>
            </div>
          </div>
        </div>
      </div>



      <div className="enable-industry4-0-art-h">
      <div className="enable-industry4-0-content-art-h">
        <h2 className="enable-industry4-0-title-art-h">How IT Solutions and Services Can Enable Industry 4.0</h2>
        <p className="enable-industry4-0-description-art-h">
          IT Solutions and Services play a pivotal role in enabling the full potential of Industry 4.0 by enhancing connectivity, intelligence, and operational synchronization within manufacturing. By leveraging advanced computing capabilities, manufacturers can harness large volumes of data generated from interconnected devices to drive insightful decision-making and process optimizations.
        </p>
        <div className="enable-industry4-0-points-art-h">
          <div className="enable-industry4-0-point-art-h">
            <div className="enable-industry4-0-icon-art-h"><MdOutlineConnectWithoutContact /></div>
            <div className="enable-industry4-0-text-art-h">
              <h3 className="enable-industry4-0-point-title-art-h">Enhanced Connectivity</h3>
              <p className="enable-industry4-0-point-text-art-h">
                IT solutions establish robust network infrastructure that facilitates seamless communication among machines, systems, and personnel. This connectivity enables real-time monitoring and management of production processes, ensuring that manufacturers can respond quickly to any operational challenges or market changes.
              </p>
            </div>
          </div>
          <div className="enable-industry4-0-point-art-h">
            <div className="enable-industry4-0-icon-art-h"><FaChartLine /></div>
            <div className="enable-industry4-0-text-art-h">
              <h3 className="enable-industry4-0-point-title-art-h">Data-Driven Insights</h3>
              <p className="enable-industry4-0-point-text-art-h">
                The integration of Big Data analytics into manufacturing processes allows for the collection and analysis of vast datasets from various sources, including IoT devices and production machinery. By utilizing predictive analytics, manufacturers can identify trends, forecast demand, and optimize supply chains, leading to minimized waste and increased profitability.
              </p>
            </div>
          </div>
          <div className="enable-industry4-0-point-art-h">
            <div className="enable-industry4-0-icon-art-h"><FaRobot /></div>
            <div className="enable-industry4-0-text-art-h">
              <h3 className="enable-industry4-0-point-title-art-h">Automation of Processes</h3>
              <p className="enable-industry4-0-point-text-art-h">
                Automation is a cornerstone of Industry 4.0, and IT services provide the necessary tools and technologies to automate routine tasks in manufacturing. From intelligent robotics to automated quality control systems, these solutions enhance operational efficiency, reduce human error, and free up personnel to focus on more strategic activities.
              </p>
            </div>
          </div>
          <div className="enable-industry4-0-point-art-h">
            <div className="enable-industry4-0-icon-art-h"><FaCogs /></div>
            <div className="enable-industry4-0-text-art-h">
              <h3 className="enable-industry4-0-point-title-art-h">Adaptable Manufacturing Systems</h3>
              <p className="enable-industry4-0-point-text-art-h">
                With the integration of AI and Machine Learning, manufacturing systems can become adaptive, learning from data patterns to adjust processes dynamically. This adaptability allows manufacturers to customize production lines on-the-fly, leading to improved product offerings and responsiveness to customer needs.
              </p>
            </div>
          </div>
          <div className="enable-industry4-0-point-art-h">
            <div className="enable-industry4-0-icon-art-h"><FaTools /></div>
            <div className="enable-industry4-0-text-art-h">
              <h3 className="enable-industry4-0-point-title-art-h">Predictive Maintenance</h3>
              <p className="enable-industry4-0-point-text-art-h">
                IT services equip manufacturers with predictive maintenance tools that utilize real-time data and machine learning algorithms to anticipate equipment failures before they occur. This proactive approach significantly reduces downtime, enhances equipment lifespan, and leads to considerable cost savings.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="case-studies-conclusion-call-to-action-art-h">
      <h2 className="section-title-art-h">Case Studies and Success Stories</h2>
      <p className="section-description-art-h">
        Connect with us to uncover case studies and success stories that resonate with your Industry 4.0 journey! Let's embark on this exciting adventure together!
      </p>
      
      <div className="points-section-art-h">
        <div className="point-item-art-h">
       
          <div className="point-content-art-h">
            <h3 className="point-title-art-h">Conclusion</h3>
            <p className="point-description-art-h">
              Industry 4.0 is transforming the manufacturing landscape, offering unparalleled opportunities for efficiency, flexibility, and growth. IT services are critical in enabling this transformation, providing the infrastructure, software solutions, and integration necessary to realize the full potential of Industry 4.0. For CIOs, CTOs, CXOs, and COOs, investing in IT services is not just a strategic decision but a crucial step towards achieving a competitive edge in the modern manufacturing industry.
            </p>
          </div>
        </div>
        <div className="point-item-art-h">
        
          {/* <div className="point-content-art-h">
            <h3 className="point-title-art-h">Call to Action</h3>
            <p className="point-description-art-h">
              Ready to unlock the transformative potential of Industry 4.0 for your manufacturing operations? Partner with our expert IT services team to harness the cutting-edge technologies and bespoke solutions that can propel your business to new heights. Don't let your competitors get ahead—embrace the future of manufacturing today.
              Contact us now to schedule a consultation and take the first step towards revolutionizing your manufacturing processes with Industry 4.0. Together, let's build a smarter, more efficient, and resilient future.
            </p>
          

          </div> */}
        </div>
      </div>
    </div>

    <div className='connect-with-us'>
      <div className='image-container-cc'>
        <img src={ContactImg} alt="Contact Us" />
        <div className='text-overlay-cc'>
          <h2>Your Vision. Our Expertise. Let's Build It Together.</h2>
<p>             Ready to unlock the transformative potential of Industry 4.0 for your manufacturing operations? Partner with our expert IT services team to harness the cutting-edge technologies and bespoke solutions that can propel your business to new heights. Don't let your competitors get ahead—embrace the future of manufacturing today.
Contact us now to schedule a consultation and take the first step towards revolutionizing your manufacturing processes with Industry 4.0. Together, let's build a smarter, more efficient, and resilient future.</p>
          <Link to="/contact-us">
          <button>Schedule a Consultation</button>
          </Link>

        </div>
      </div>
    </div>
    
    </>
  );
};

export default HeroSection;
