import React,{useEffect} from "react";
import "../XD/XD.css";
import WHero from "../../assets/w3Hero.webp";
import OtherStudios from "../../components/Cards/mainCards"
import ConnectWithUs from "../../components/ConnectWithUs/ConnectWithUs";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Link } from "react-router-dom";
import WebGif1 from "../../assets/StudiosGifs/W3-1.gif"
import WebGif2 from "../../assets/StudiosGifs/W3-2.gif"
import WebGif3 from "../../assets/StudiosGifs/W3-3.gif"
import useScrollReveal from '../XD/Animation';
import { motion } from 'framer-motion';


function Wepage() {
  const revealVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ref1, controls1] = useScrollReveal({ triggerOnce: false, threshold: 0.1 });
  const [ref2, controls2] = useScrollReveal({ triggerOnce: false, threshold: 0.1 });
  const [ref3, controls3] = useScrollReveal({ triggerOnce: false, threshold: 0.1 });
  return (<>
  
      <div className="xd-hero-container">
        <div className="xd-hero-img-cc">
          <img src={WHero} alt="Xd hero" />

          <div className="xd-hero-txt-cnt">
            <Link to="/studios" className="head-back-btn">
              <MdOutlineArrowBackIos className="back-icon-btn" />
              <h5>WEB-3 STUDIO</h5>
            </Link>

            <h3>
              Unlock the Power of Connected Systems & Data with Expert
              Integration & Analytics
            </h3>
            <p>
            At the forefront of Web3 development, we integrate AI/ML, IoT, and
            blockchain technologies to build innovative solutions. Our expertise
            drives the creation of intelligent, connected, and decentralized
            applications that revolutionize industries and enhance digital
            ecosystems.
            </p>
            {/* <button>Schedule a Consultation</button> */}
          </div>
        </div>
      </div>


      <div className="card-st-container">
      <motion.div
          className="card-sd-item"
          ref={ref1}
          initial="hidden"
          animate={controls1}
          variants={revealVariants}
        >
          <div className="text-cnt-sd">
            <h2 style={{ color: "#00AECF" }}>Leading in Web3 Technologies  </h2>
            <h3>WHAT WE DO</h3>
            {/* <p>
            We create designs that are easy to understand, easy to use, and
            intuitive. Our goal is to make complex concepts accessible and engaging
            for users.
          </p> */}
            <p>
            With our Data Science skills, you can uncover insights, identify trends, and make informed decisions. Create seamless, decentralised data structures to ensure effective flow and storage. Use the power of decentralised technology to create secure and transparent solutions. Provide users with cutting-edge Web3 experiences.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={WebGif1} alt="" />
          </div>
          </motion.div>


  <motion.div
          className="card-sd-item mb-reverse"
          ref={ref2}
          initial="hidden"
          animate={controls2}
          variants={revealVariants}
          // style={{ flexDirection: 'row-reverse' }}
        >
          <div className="text-cnt-sd">
            <h2 style={{ color: "#00AECF" }}>Navigating Web3 Complexity </h2>
            <h3>HOW WE DO IT</h3>
            <p>
            Our team navigates the difficulties of Web3, leveraging Data Science knowledge to create seamless, decentralised data infrastructures. We use decentralised technology to deliver secure and transparent solutions.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={WebGif2} alt="" />
          </div>
          </motion.div>

          <motion.div
          className="card-sd-item"
          ref={ref3}
          initial="hidden"
          animate={controls3}
          variants={revealVariants}
        >
          <div className="text-cnt-sd">
            <h2 style={{ color: "#00AECF" }}>Future-Ready Web3 Solutions  </h2>
            <h3>FINAL PRODUCT</h3>
            <p>
            The end result is a future-ready Web3 solution that offers analytics, trend predictions, safe and transparent solutions, and compelling user experiences, putting your company at the forefront of the decentralised world.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={WebGif3} alt="" />
          </div>
          </motion.div>
      </div>


    <OtherStudios/>
    <ConnectWithUs/>
    </>
  );
}

export default Wepage;
