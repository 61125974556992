import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Hero.css";
import Video from "../../assets/BgVid4.mp4";
import { Link } from "react-router-dom";

const HeroSection = () => {
  const paragraphs = [
    <Link to="/experience-design" className="flashing-text">
      Product Engineering Services: Engineer. Build. Innovate.
    </Link>,
    "Experience Design: Craft. Elevate. Experience",
    "Custom Development: Built for Speed",
    "SaaS: Unleash Your Potential",
    "Future-Proof: Blockchain & IoT",
    "Data Analytics: Data. Insights. Action",
    "Integration & Automation: Connect. Automate. Seamless",
  ];

  const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentParagraphIndex(
          (prevIndex) => (prevIndex + 1) % paragraphs.length
        );
        setFade(true);
      }, 500);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [paragraphs.length]);

  return (
    <header className="hero">
      <video autoPlay loop muted className="background-video">
        <source src={Video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="container99">
        <motion.h1
          className="heading99"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          We Don’t Do The Usual; We Solve Business Problems
        </motion.h1>
        <div className="paragraph-container99">
          <motion.p
            className={`paragraph99 ${fade ? "fade-in" : "fade-out"}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: fade ? 1 : 0, y: fade ? 0 : -20 }}
            transition={{ duration: 0.5 }}
          >
            {paragraphs[currentParagraphIndex]}
          </motion.p>
        </div>
        <div className="para-hero-home">
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            At Supernal, we craft innovative technology solutions tailored to
            your unique business needs. Embracing the innovative concept of
            Digital Studios, we ensure each project receives dedicated attention
            and expertise, similar to Product Studios. Our team thrives on
            turning concepts into reality, taking a proactive approach to
            problem-solving and innovation.
          </motion.p>
          <motion.p
            className="text22"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.7 }}
          >
            With our expertise and creativity, we provide the tools and resources needed to bring ideas to life,
            transforming possibilities into successful solutions. With a
            commitment to seamless delivery and meticulous attention to diverse
            requirements, we are the reliable technology partner you can trust
            for offshore software development.
          </motion.p>
        </div>
      </div>
    </header>
  );
};

export default HeroSection;

