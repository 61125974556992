import React, { useEffect } from "react";
import "./EcomQcom.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import image1 from "../../../assets/IndustriesAssets/ecomxd.webp";
import image2 from "../../../assets/IndustriesAssets/ecomSystem .webp";
import image3 from "../../../assets/IndustriesAssets/ecomAi.webp";
import image4 from "../../../assets/IndustriesAssets/ecomSolutions.webp";
import Connect from "../../../components/ConnectWithUs/ShoppingConnectWithUs/ShoppingConnectWithUs";


gsap.registerPlugin(ScrollTrigger);
function Shoping() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const items = document.querySelectorAll(".industry__item");

    items.forEach((item) => {
      const itemTitle = item.querySelector(".industry__item__title");
      const itemImg = item.querySelector(".industry__item img");
      const headings = itemTitle.querySelectorAll("h1, h2, p");

      const itemTL = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: "top 100%",
          end: "bottom 55%",
          scrub: 3,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          // markers: true,
        },
      });

      itemTL
        .fromTo(
          itemTitle,
          { opacity: 0, y: "20%" },
          { opacity: 1, y: "0%", ease: "power2.inOut", duration: 1.5 },
          0
        )
        .fromTo(
          headings,
          { opacity: 0, y: "10%" },
          { opacity: 1, y: "0%", ease: "power2.inOut", stagger: 0.3, duration: 1 },
          0
        )
        .fromTo(
          itemImg,
          { scale: 0, x: "0vw", y: "50vh", rotate: -30 },
          {
            scale: 1.2,
            x: "-10vw",
            y: "0vh",
            rotate: 0,
            ease: "power2.inOut",
            duration: 15,
          },
          "+=15"
        )
        .to(
          itemImg,
          {
            scale: 0,
            x: "50vw",
            y: "-50vh",
            rotate: 30,
            ease: "power2.inOut",
            duration: 15,
          },
          "+=15"
        );
    });
  }, []);


  return (
    <>
      <div className="ShoppingWaveContainer">
        <div className="wavesShopping">
          <svg
            className="wavesShopping"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 25 150 30"
            preserveAspectRatio="none"
            shape-rendering="auto"
          >
            <defs>
              <style type="text/css"></style>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
              <linearGradient
                id="linear-gradient"
                x1="3675.66"
                y1="24.41"
                x2="3675.66"
                y2="24"
                gradientTransform="translate(5881855.72 4816) rotate(180) scale(1600 198)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#6cc9f0" />
                <stop offset="1" stop-color="#fff" stop-opacity="0" />
              </linearGradient>
            </defs>
            <g className="parallax cls-1">
              <use
                xlinkHref="#gentle-wave"
                className="cls-2"
                x="48"
                y="0"
                opacity="70%"
              />
              <use
                xlinkHref="#gentle-wave"
                className="cls-2"
                x="48"
                y="3"
                opacity="50%"
              />
              <use
                xlinkHref="#gentle-wave"
                className="cls-2"
                x="48"
                y="5"
                opacity="30%"
              />
              <use xlinkHref="#gentle-wave" className="cls-2" x="48" y="7" />
            </g>
          </svg>
        </div>

        <div className="ShoppingImagesSection">
          <div className="ShoppingHeadingStyle">
            <h1>
              Transforming Commerce with Cutting - Edge Technology Solutions
            </h1>
          </div>
          <div className="Shopping-intro-content">
            <p>
              At Supernal Infotech, we deeply understand the dynamic nature of
              the commerce sector, encompassing retail, e-commerce, and quick
              commerce. Our extensive experience in these industries has enabled
              us to deliver innovative solutions that enhance operational
              efficiency, ensure a seamless omnichannel user experience, and
              drive growth. Here’s how we have successfully helped commerce
              customers and how we can help you too:
            </p>
          </div>
        </div>
      </div>
      {/* ------ */}

      


      <div className="industries__container">
        <div className="industries__list">
          <div className="industry__item">
            <div className="industry__item__title">
              <h1> EXPERIENCE DESIGN </h1>
              <h2>CRAFTING USER-CENTRIC, OMNI CHANNEL EXPERIENCES</h2>
              <p>
                  Our experience design solutions focus on creating intuitive
                  and engaging user interfaces for web and mobile applications,
                  greatly improving the shopping experience. We ensure a
                  seamless user journey across all touchpoints to promote
                  customer loyalty and satisfaction. By utilizing advanced
                  technologies and user-centric methodologies, we offer
                  personalized shopping experiences tailored to each customer,
                  streamlined checkout processes, and various payment options.
                </p>
            </div>
            <img src={image1} alt="Item 1" />
          </div>

          <div className="industry__item">
            <div className="industry__item__title">
              <h1>  SYSTEMS INTEGRATION -</h1>
              <h2>   CREATING SEAMLESS ECOSYSTEMS</h2>
              <p>
                  The integration of various systems is vital in establishing a
                  unified omni-channel experience. Our cutting-edge solutions
                  effortlessly blend online and offline channels, ensuring a
                  consistent and enhanced customer journey. With our expertise
                  in integration services, we promise a smooth transfer of data
                  across numerous platforms, demolishing data barriers and
                  amplifying operational efficiency. By seamlessly connecting
                  different systems, we create an interconnected ecosystem that
                  optimizes the overall customer experience.
                </p>
            </div>
            <img src={image2} alt="Item 1" />
          </div>


          <div className="industry__item">
            <div className="industry__item__title">
              <h1>ANALYTICS, AI/ML AND EMERGING TECHNOLOGIES</h1>
              <h2>ENHANCING COST EFFECTIVENESS AND COMPETITIVENESS</h2>
              <p className="desc-ind-items">
                  Our mastery of data analytics has equipped retailers with
                  valuable insights, streamlining decision-making processes and
                  spotting emerging market trends. Through predictive analytics
                  solutions, businesses can predict customer preferences,
                  efficiently manage inventory, and tailor marketing strategies.
                  Leveraging AI and machine learning, we have boosted
                  personalization efforts, recommended products, and refined
                  pricing strategies. With the integration of blockchain
                  technology, we ensure secure transactions and supply chain
                  transparency, nurturing trust and dependability.
                </p>
            </div>
            <img src={image3} alt="Item 1" />
          </div>


          <div className="industry__item">
            <div className="industry__item__title">
              <h1> CUSTOM SOLUTIONS </h1>
              <h2> DESIGNING TAILORED SOLUTIONS THAT MEET YOUR NEEDS</h2>
              <p className="desc-ind-items">
                  Our meticulously crafted software solutions aim to elevate
                  efficiency, elevate customer experiences, and fortify security
                  measures within various establishments. Through the
                  revitalization of outdated systems and the integration of
                  cutting-edge technologies into our innovative designs, we
                  empower retail businesses to thrive and evolve in the dynamic
                  and constantly changing market environment of today.
                </p>
            </div>
            <img src={image4} alt="Item 1" />
          </div>
      
        </div>
      </div>

      <Connect />
    </>
  );
}

export default Shoping;
