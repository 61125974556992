// import React from 'react'
// import Bia from "../../assets/BIA.png";
// function OurAnalyticsMethodology() {
//   return (<>
//    <div className="dc-main-head-text">
//         <h1> Our Analytics Methodology </h1>
//       </div>

//       <div className="am-dc-cnt">
//         <div className="am-dc-txt-cnt">
//           <div className="am-dc-item-text">
//             <h5> DATA ACQUISITION & CONSOLIDATION</h5>
//             <p>
//               Our specialists engage in the consolidation and acquisition of iin
//               one unified location for smoother information management,
//               enhancing productivity, lowering operational expenses, and
//               optimizing the utilization of customer data.
//             </p>
//           </div>

//           <div className="am-dc-item-text">
//             <h5> DATA CLEANSING</h5>
//             <p>
//               Our experts assist in obtaining refined data from its raw state to
//               appropriate downstream applications, such as modeling and
//               analytics
//             </p>
//           </div>

//           <div className="am-dc-item-text">
//             <h5> DATA CATALOGING</h5>
//             <p>
//               Our solutions effortlessly tap into top-notch data via data
//               cataloging to create insightful business reports, extract value,
//               make well-informed decisions
//             </p>
//           </div>

//           <div className="am-dc-item-text">
//             <h5> MODELING & ANALYSIS</h5>
//             <p>
//               Our Data Modeling & Analysis services offer clients a
//               comprehensive perspective on physical, conceptual, and logical
//               data predictive models utilizing Statistical/ML/Deep Learning
//               methods.
//             </p>
//           </div>

//           <div className="am-dc-item-text">
//             <h5>BI - Decision Support System/Data Visualisation/Analytics</h5>
//             <p>
//               Our solutions integrate multiple information sources,
//               sophisticated analytical models to provide a strong groundwork for
//               decision-making processes.
//             </p>
//           </div>

//           <div className="am-dc-item-text">
//             <h5> DATA GOVERNANCE</h5>
//             <p>
//               Our specialization in Governance encompasses Data Profiling at
//               Source, Master Data Governance, and Data Quality Metrics and
//               Scorecards.
//             </p>
//           </div>
//         </div>
//         <div className="am-dc-img-cnt">
//           <img src={Bia} alt="Agri" />
//         </div>
//       </div>
  
//   </>
    
//   )
// }

// export default OurAnalyticsMethodology
import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Bia from "../../assets/BIA.png";

// Define animation variants for sliding in from the left
const slideInVariants = {
  hidden: { opacity: 0, x: -100 }, // Start position: off-screen left
  visible: { opacity: 1, x: 0, transition: { duration:0.9, ease: "easeOut" } } // End position: on-screen
};

// Helper function to create animation component
const AnimatedItem = ({ children, index }) => {
  const { ref, inView, entry } = useInView({
    triggerOnce: false, // Ensure that the animation can be triggered multiple times
    threshold: 0.1
  });

  // Only apply animation if item is in view
  const shouldAnimate = inView;

  return (
    <motion.div
      className="am-dc-item-text"
      ref={ref}
      variants={slideInVariants}
      initial="hidden"
      animate={shouldAnimate ? "visible" : "hidden"}
      transition={{ delay: index * 0.3, duration: 0.9 }} // Staggered timing
    >
      {children}
    </motion.div>
  );
};

const OurAnalyticsMethodology = () => {
  return (
    <>
      <div className="dc-main-head-text">
        <h1>Our Analytics Methodology</h1>
      </div>

      <div className="am-dc-cnt">
        <div className="am-dc-txt-cnt">
          {[
            { title: "DATA ACQUISITION & CONSOLIDATION", text: "Our specialists engage in the consolidation and acquisition of data in one unified location for smoother information management, enhancing productivity, lowering operational expenses, and optimizing the utilization of customer data." },
            { title: "DATA CLEANSING", text: "Our experts assist in obtaining refined data from its raw state to appropriate downstream applications, such as modeling and analytics." },
            { title: "DATA CATALOGING", text: "Our solutions effortlessly tap into top-notch data via data cataloging to create insightful business reports, extract value, make well-informed decisions." },
            { title: "MODELING & ANALYSIS", text: "Our Data Modeling & Analysis services offer clients a comprehensive perspective on physical, conceptual, and logical data predictive models utilizing Statistical/ML/Deep Learning methods." },
            { title: "BI - Decision Support System/Data Visualization/Analytics", text: "Our solutions integrate multiple information sources, sophisticated analytical models to provide a strong groundwork for decision-making processes." },
            { title: "DATA GOVERNANCE", text: "Our specialization in Governance encompasses Data Profiling at Source, Master Data Governance, and Data Quality Metrics and Scorecards." }
          ].map((item, index) => (
            <AnimatedItem key={index} index={index}>
              <h5>{item.title}</h5>
              <p>{item.text}</p>
            </AnimatedItem>
          ))}
        </div>
        <div className="am-dc-img-cnt">
          <img src={Bia} alt="Agri" />
        </div>
      </div>
    </>
  );
};

export default OurAnalyticsMethodology;
