import React from "react";
import "./Technologies.css"
import Frontend from "./FrontEnd/FrontEnd";
import BackendOfferings from "./BackendOfferings/BackendOfferings"
import MobileApps from "./MobileApps/MobileApps";
import AppPlatforms from "./AppPlatforms/AppPlatforms";
import CloudServices from "./CloudServices/CloudServices";
import { HashLink } from "react-router-hash-link";


const tabData = [
	[
	  {
		name:   <HashLink className="Tab" to="/technologies/#frontend-solutions">Frontend Solutions</HashLink>, 
		content: <Frontend/>,
	  },
	  {
		name:   <HashLink className="Tab" to="/technologies/#backend-offerings">Backend Offerings </HashLink>, 
		content: <BackendOfferings/>,
	  },
	  {
		name:   <HashLink className="Tab" to="/technologies/#mobile-apps">Mobile Apps</HashLink>, 
		content: <MobileApps/>,
	  },
	    {
		name:  <HashLink className="Tab" to="/technologies/#app-platforms"> App Platforms</HashLink>, 
		content:<AppPlatforms/>,
	  },
	  {
		name:  <HashLink className="Tab" to="/technologies/#cloud-services">Cloud Services</HashLink>, 
		content:<CloudServices/>,
	  },
	
	],

  ];
  
  /*
  *
  * App wrapper
  *
  */
  const Technologies = () =>
	<div className="App">
	  <TabContainer defaultTab={0} tabData={tabData[0]}/>
	 
	</div>;
  
  /*
  *
  * TabPanel component. 
  * Displays content data passed down by TabContainer.
  *
  */
  const TabPanel = ({ content }) => 
	<div className="TabPanel">
	  <p>{content}</p>
	</div>;
  
  /*
  *
  * Tab component.
  *
  */
  class Tab extends React.Component {
	_handleClick(e) {
	  const { setCurrentTab, index } = this.props;
	  setCurrentTab(index);
	}
  
	render() {
	  const { text, isSelected } = this.props;
	  return (
		<li 
		  className={`Tab${isSelected ? ' is-selected' : ''}`} 
		  onClick={this._handleClick.bind(this)}>
		  {text}
		</li>
	  );
	}
  }
  
  /*
  *
  * TabContainer component.
  *
  */
  class TabContainer extends React.Component {
	// scroll from top

	componentDidUpdate() {
		window.scrollTo(0,0);
	  }
	// -----------------------
	constructor(props) {
	  super(props);
  
	  // Set initial state
	  this.state = {
		currentTab: this.props.defaultTab,
	  };
	  
	  // Dummy data
	  this._tabData = this.props.tabData;
	}
  
	_setCurrentTab(currentTab) {
	  // Don't re-render if current tab is clicked again
	  if (this.state.currentTab === currentTab) {
		return;
	  }
	  this.setState({ currentTab });
	}
  
	_renderTabs() {
	  // Map data to Tab component. 
	  // Pass index down so we can send it up to this.state later.
	  return (
		<ul className="Tabs">
		  {
			this._tabData.map((tab, index) => {
			  return (
				<Tab
				  index={index}
				  text={tab.name}
				  isSelected={this.state.currentTab === index}
				  setCurrentTab={this._setCurrentTab.bind(this)}
				/>
			  )
			})
		  }
		</ul>
	  );
	}
  
	_renderTabPanel() {
	  // Map data to TabPanel component
	  // eslint-disable-next-line array-callback-return
	  return this._tabData.map((tab, index) => {
		if (this.state.currentTab === index) {
		  return (
			<TabPanel 
			  content={tab.content} />
		  );
		}
	  });
	}
  
	render() {
	  const Tabs = this._renderTabs();
	  const TabPanel = this._renderTabPanel();
	  return (
		<div className="TabContainer">
		  {Tabs}
		  {TabPanel}
		</div>
	  );
	}
  }
  
  /*
  *
  * Render App
  *
  */
 export default Technologies;
  