// import React, { useState } from "react";
// import "./Form.css";
// import axios from "axios";
// function Form() {
//   const [values, setvalues] = useState({
//     firstName: "",
//     lastName: "",
//     phone: "",
//     email: "",
//     company: "",
//     message: "",
//   });
//   const [submitted, setSubmitted] = useState(false);
//   const [valid, setValid] = useState(false);

//   const handleFirstNameInputChange = (event) => {
//     const limit = 30;
//     setvalues({
//       ...values,
//       firstName: event.target.value.replace(/[^a-z]/gi, "").slice(0, limit),
//     });
//   };
//   const handleLastNameInputChange = (event) => {
//     const limit = 30;
//     setvalues({
//       ...values,
//       lastName: event.target.value.replace(/[^a-z]/gi, "").slice(0, limit),
//     });
//   };
//   const handleemailInputChange = (event) => {
//     setvalues({ ...values, email: event.target.value });
//   };
//   const handlemobileNumberInputChange = (event) => {
//     const limit = 10;
//     const reg = /^[0-9\b]+$/;

//     if (event.target.value === "" || reg.test(event.target.value))
//       setvalues({ ...values, phone: event.target.value.slice(0, limit) });
//   };
//   const handleCompanyInputChange = (event) => {
//     const limit = 30;
//     setvalues({ ...values, company: event.target.value.slice(0, limit) });
//   };
//   const handleMessageInputChange = (event) => {
//     const limit = 200;
//     setvalues({ ...values, message: event.target.value.slice(0, limit) });
//   };
//   const handleSubmit = (event) => {
//     event.preventDefault();

//     const first_name = event.target.firstName.value;
//     const last_name = event.target.lastName.value;
//     const email = event.target.email.value;
//     const phone = event.target.phone.value;
//     const message = event.target.message.value;
//     const company = event.target.company.value;

//     const data = { first_name, last_name, email, phone, message, company };

//     axios
//       .post("http://3.22.233.85/customers/customer/", data)
//       .then((response) => {
//         console.log(response);
//         event.target.reset();
//       })
//       .catch((error) => {
//         console.log(error);
//       });

//     if (
//       values.firstName &&
//       values.lastName &&
//       values.email &&
//       values.phone &&
//       values.company &&
//       values.message
//     ) {
//       setValid(true);
//     }
//     setSubmitted(true);

//     setvalues({
//       firstName: "",
//       lastName: "",
//       email: "",
//       phone: "",
//       message: "",
//       company: "",
//     });

//     setTimeout(function () {
//       window.location = "/";
//     }, 4000);
//   };

//   return (
//     <>
//       <div className="form-section-22">
//         <div className="Form_Section">
//           {/* ---------------- */}
//           <div className="ConnectWith_Us">
//             <form className="register-form" onSubmit={handleSubmit}>
//               <div className="form-heading-33">
//                 <h2>Request for Services</h2>
//                 <p>
//                   Please inform us about the service you wish to avail by
//                   filling out the form provided below. We will promptly reach
//                   out to you.
//                 </p>
//               </div>
//               <div className="inputFields-22">
//                 <input
//                   name="firstName"
//                   type="text"
//                   class="feedback-input"
//                   placeholder="First name"
//                   maxLength="30"
//                   minLength="3"
//                   autoComplete="off"
//                   required
//                   onChange={handleFirstNameInputChange}
//                   value={values.firstName}
//                 />
//                 <br />
//                 <input
//                   name="lastName"
//                   type="text"
//                   class="feedback-input"
//                   placeholder="Last name"
//                   maxLength="30"
//                   minLength="3"
//                   autoComplete="off"
//                   required
//                   onChange={handleLastNameInputChange}
//                   value={values.lastName}
//                 />
//                 <br />
//                 <input
//                   name="email"
//                   type="email"
//                   class="feedback-input"
//                   autoComplete="off"
//                   placeholder="Email"
//                   required
//                   onChange={handleemailInputChange}
//                   value={values.email}
//                 />
//                 <br />
//                 <input
//                   name="phone"
//                   type="tel"
//                   class="feedback-input"
//                   placeholder="Mobile number"
//                   minLength="10"
//                   maxLength="10"
//                   autoComplete="off"
//                   required
//                   value={values.phone}
//                   onChange={handlemobileNumberInputChange}
//                 />{" "}
//                 <br />
//                 <input
//                   name="company"
//                   type="text"
//                   class="feedback-input"
//                   placeholder="Company"
//                   maxLength="30"
//                   minLength="3"
//                   autoComplete="off"
//                   required
//                   value={values.company}
//                   onChange={handleCompanyInputChange}
//                 />{" "}
//                 <br />
//                 <textarea
//                   name="message"
//                   type="text"
//                   class="feedback-input"
//                   placeholder="Please provide additional information so we can better respond to your request"
//                   maxLength="200"
//                   minLength="3"
//                   autoComplete="off"
//                   required
//                   value={values.message}
//                   onChange={handleMessageInputChange}
//                 ></textarea>
//                 <div className="btnSubmit">
//                   <button type="sumbit" className="btnFormSubmit">
//                     Submit
//                   </button>
//                   {submitted && valid ? (
//                     <div className="success-message">
//                       <p>
//                         Thank you for submitting the form! <br />
//                         Your information has been received and our
//                         representative will be in touch with you soon.{" "}
//                       </p>
//                     </div>
//                   ) : null}
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//         <div className="Lets_talkSection">
//           <div className="Lets_TalkText">
//             <h2>
//               {" "}
//               <i class="fa-sharp fa-solid fa-phone"></i> &nbsp;Let’s Talk
//             </h2>
//             <div className="PhoneCallSection">
//               <p>
//                 US:&nbsp;
//                 <a className="" href="tel:{phone}">
//                   {" "}
//                   +1-206-866-5159{" "}
//                 </a>
//                 <br />
//                 India:&nbsp;
//                 <a href="tel:{phone}"> +91 9550008443</a>
//               </p>
//             </div>

//             <div className="EmailSection">
//               <h2>
//                 <i class="fa-solid fa-envelope"></i> &nbsp;Need help? Email us
//               </h2>
//               <a href="mailto:{email}?subject={subject}&body={content}">
//                 connect@supernal-it.com
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Form;


// emailJs

import React, { useRef,useState } from 'react';
import emailjs from '@emailjs/browser';
import "./Form.css";
import PhoneInput from "react-phone-input-2";
function Form() {
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hmr6kt8",
        "template_q4hjasi",
        form.current,
        "EM5itf02kEAs25gIg"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
          setSubmitted(true);
          setTimeout(function () {
            window.location = "/";
          }, 4000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  
  return (
    <>
      <div className="form-section-22">
        <div className="Form_Section">
          {/* ---------------- */}
          <div className="ConnectWith_Us">
            <form className="register-form" ref={form} onSubmit={sendEmail}>
              <div className="form-heading-33">
                <h2>Request for Services</h2>
                <p>
                  Please inform us about the service you wish to avail by
                  filling out the form provided below. We will promptly reach
                  out to you.
                </p>
              </div>
              <div className="inputFields-22">
                <input
                  name="firstName"
                  type="text"
                  class="feedback-input"
                  placeholder="First name"
                  maxLength="30"
                  minLength="3"
                  autoComplete="off"
                  required
                />
                <br />
                <input
                  name="lastName"
                  type="text"
                  class="feedback-input"
                  placeholder="Last name"
                  maxLength="30"
                  minLength="3"
                  autoComplete="off"
                  required

                />
                <br />
                <input
                  name="email"
                  type="email"
                  class="feedback-input"
                  autoComplete="off"
                  placeholder="Email"
                  required

                />
                <br />
                <PhoneInput
          inputStyle={{width: '100%',background:"#e8eeef",fontSize:"16px",height:"50px",borderRadius:"10px",}}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
                placeholder: "Enter phone number", 
           
            }}
            country={'in'} // Default country
            value={phoneNumber}
            onChange={setPhoneNumber}
            className="mobile-number21"
        

          />
                <br />
                <input
                  name="company"
                  type="text"
                  class="feedback-input"
                  placeholder="Company"
                  maxLength="30"
                  minLength="3"
                  autoComplete="off"
                  required

                />{" "}
                <br />
                <textarea
                  name="message"
                  type="text"
                  class="feedback-input"
                  placeholder="Please provide additional information so we can better respond to your request"
                  maxLength="200"
                  minLength="3"
                  autoComplete="off"
                  required

                ></textarea>
                <div className="btnSubmit">
                  <button type="sumbit" className="btnFormSubmit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
               {/* Thank you popup */}
        {submitted && (
          <div className="success-message">
            <p>Thank you for submitting the form! <br />
                        Your information has been received and our
                        representative will be in touch with you soon.</p>
          </div>
        )}
          </div>
        </div>
        <div className="Lets_talkSection">
          <div className="Lets_TalkText">
            <h2>
              {" "}
              <i class="fa-sharp fa-solid fa-phone"></i> &nbsp;Let’s Talk
            </h2>
            <div className="PhoneCallSection">
              <p>
                US:&nbsp;
                <a className="" href="tel:{phone}">
                  {" "}
                  +1-206-866-5159{" "}
                </a>
                <br />
                India:&nbsp;
                <a href="tel:{phone}"> +91 9550008443</a>
              </p>
            </div>

            <div className="EmailSection">
              <h2>
                <i class="fa-solid fa-envelope"></i> &nbsp;Need help? Email us
              </h2>
              <a href="mailto:{email}?subject={subject}&body={content}">
                connect@supernal-it.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
