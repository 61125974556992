import React from "react";
import "./Map.css";

import MapBg from "../../../assets/map.webp"
// import MapVideo from "../../../assets/AboutMain.mp4";
// import Agri from "../../../assets/IndustriesSVG2/agri.svg"
// import Manufacturing from "../../../assets/IndustriesSVG2/manufacturing.svg"
// import Healthcare from "../../../assets/IndustriesSVG2/health.svg"
// import Fintech from "../../../assets/IndustriesSVG2/fintech.svg"
// import Retail from "../../../assets/IndustriesSVG2/retail.svg"
// import Education from "../../../assets/IndustriesSVG2/edutech.svg"
// import Sports from "../../../assets/IndustriesSVG2/sports.svg"
// import Entertainment from "../../../assets/IndustriesSVG2/entertainment.svg"
// import Qcom from "../../../assets/IndustriesSVG2/qcom.svg"
// import HealthGaming from "../../../assets/IndustriesSVG2/real estate.svg"
// import Telecom from "../../../assets/IndustriesSVG2/telecom.svg"




function Map() {
  return (
    <div className="Map-video-container">
      <div className="map-title">
      {/* <video autoPlay loop muted playsInline className="video-map">
        <source src={MapVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <img src={MapBg }     alt=""/>
      </div>

      {/* <div className="industries-list-container">
        <div className="industry-item">
          <img src={Agri} />
          <p>Agri</p>
        </div>
        <div className="industry-item">
          <img src={Manufacturing} />
          <p>Manufacturing</p>
      </div>
      <div className="industry-item">
          <img src={Healthcare} />
          <p>Healthcare</p>
      </div>
      <div className="industry-item">
          <img src={Fintech} />
          <p>Fintech</p>
      </div>
      <div className="industry-item">
          <img src={Retail} />
          <p>Retail</p>
      </div>
      <div className="industry-item">
          <img src={Education} />
          <p>Education</p>
      </div>
      <div className="industry-item">
          <img src={Sports} />
          <p>Sports</p>
      </div>
      <div className="industry-item">
          <img src={Entertainment} />
          <p>Entertainment</p>
      </div>
      <div className="industry-item">
          <img src={Qcom} />
          <p>QCOM</p>
      </div>
      <div className="industry-item">
          <img src={HealthGaming} />
          <p>Health & Gaming</p>
      </div>
      <div className="industry-item">
          <img src={Telecom} />
          <p>Telecom</p>
      </div>
      </div> */}

      {/* <div className="industries-list-container2">
        <div className="industry-item2">
          <p>
         
            At Supernal, we craft innovative technology solutions tailored to
            your unique business needs. Embracing the innovative concept of
            Digital Studios, we ensure each project receives dedicated attention
            and expertise, similar to Product Studios.
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default Map;
