import React,{useEffect} from 'react'
import "./Contact.css"
import Form from "./Form/Form"

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='Contact_UsSection'>
        <div className='ContactUs_Header'>
            <div className='ContactUs_Header-text'>
      <h1>Contact Us</h1>
      <p>
         Ready to turn your business idea into a reality? Supernal Infotech will unlock all
          challenges. Just give us a call or mail and we will make your vision come alive.
      </p>
      </div>
      </div>
      
      <Form/>
     
    </div>
  )
}

export default Contact
