import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Banking.css"; // Ensure you create a stylesheet with the same class names
import image1 from "../../../assets/IndustriesAssets/digitalBanking.webp";
import image2 from "../../../assets/IndustriesAssets/process-auto .webp";
import image3 from "../../../assets/IndustriesAssets/customSolDev .webp";
import image4 from "../../../assets/IndustriesAssets/fintechXD.webp";
import ConnectWithUs from "../../../components/ConnectWithUs/ConnectWithUs"

gsap.registerPlugin(ScrollTrigger);

const IndustriesComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const items = document.querySelectorAll(".industry__item");

    items.forEach((item) => {
      const itemTitle = item.querySelector(".industry__item__title");
      const itemImg = item.querySelector(".industry__item img");
   

      const itemTL = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: "top 100%",
          end: "bottom 55%",
          scrub: 3,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          // markers: true,
        },
      });

      itemTL
        .fromTo(
          itemTitle,
          { opacity: 0, y: "20%" },
          { opacity: 1, y: "0%", ease: "power2.inOut", duration: 1.5 },
          0
        )
      
        .fromTo(
          itemImg,
          { scale: 0, x: "0vw", y: "50vh", rotate: -30 },
          {
            scale: 1.2,
            x: "-10vw",
            y: "0vh",
            rotate: 0,
            ease: "power2.inOut",
            duration: 15,
          },
          "+=15"
        )
        .to(
          itemImg,
          {
            scale: 0,
            x: "50vw",
            y: "-50vh",
            rotate: 30,
            ease: "power2.inOut",
            duration: 15,
          },
          "+=5"
        );
    });
  }, []);

  return (
    <>
      <div className="Banking-img-container">
        <div className="Banking-title-sec">
          <h1>
            Have a game-changing vision for the future of banking and finance?
            Let us be your gateway to global success!
          </h1>
        </div>
        <div className="Banking-heading-intro-section">
          <p>
            At Supernal Infotech Pvt Ltd, we have a deep understanding of the
            FinTech sector's unique challenges and opportunities. Our extensive
            experience in this industry has enabled us to deliver tailored
            technology solutions that enhance operational efficiency, ensure
            regulatory compliance, and drive innovation. Here’s how we have
            successfully helped banking and finance customers and how we can
            help you too:
          </p>
        </div>
      </div>

      <div className="industries__container">
        <div className="industries__list">
          <div className="industry__item">
            <div className="industry__item__title">
              <h1>DIGITAL BANKING SOLUTIONS</h1>
              <h2>INNOVATIVE DIGITAL WALLETS, VIRTUAL ACCOUNTS AND MORE</h2>
              <p>
                We have developed state-of-the-art digital wallets and virtual accounts to offer customers secure and convenient digital banking. These solutions seamlessly integrate with payment gateways for smooth and secure financial transactions. Users are empowered to effortlessly manage their finances while upholding top-notch security and reliability, providing a superior banking experience in today's digital era.
              </p>
            </div>
            <img src={image1} alt="Item 1" />
          </div>

          <div className="industry__item">
            <div className="industry__item__title">
              <h1>PROCESS AUTOMATION </h1>
              <h2>ENHANCING OPERATIONAL EFFICIENCY</h2>
              <p>
              We've automated repetitive tasks such as transaction processing, compliance checks, and customer onboarding, reducing errors and increasing efficiency. The deployment of Integrations and robotic process automation (RPA) has managed high-volume tasks, freeing employees to focus on more strategic initiatives. This automation enhances operational efficiency, allowing financial institutions to allocate resources more effectively and improve overall productivity.
              </p>
            </div>
            <img src={image2} alt="Item 1" />
          </div>


          <div className="industry__item">
            <div className="industry__item__title">
              <h1>CUSTOM SOLUTIONS DEVELOPMENT</h1>
              <h2>TAILORED SOLUTIONS FOR COMPLEX NEEDS</h2>
              <p>
              Our bespoke software solutions streamline operations, improve customer experiences, and enhance security for financial institutions. By modernizing legacy systems, we've significantly increased agility and performance, enabling banks to stay competitive. These tailored solutions are designed to address complex needs, ensuring that financial institutions can efficiently manage their operations and provide superior service to their customers.
              </p>
            </div>
            <img src={image3} alt="Item 1" />
          </div>


          <div className="industry__item">
            <div className="industry__item__title">
              <h1>EXPERIENCE DESIGN </h1>
              <h2>CRAFTING BEST IN CLASS USER-CENTRIC APPLICATIONS</h2>
              <p>
              Our expertise in designing intuitive and engaging digital interfaces for web, mobile has significantly enhanced customer satisfaction and loyalty. By optimizing mobile and web applications, we've ensured a seamless and consistent user experience across all touchpoints. These user-centric designs not only improve usability but also drive customer engagement, making digital interactions with financial services both enjoyable and efficient.
              </p>
            </div>
            <img src={image4} alt="Item 1" />
          </div>
      
        </div>
      </div>
      <ConnectWithUs/>
    </>
  );
};

export default IndustriesComponent;
