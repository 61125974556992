import React,{useEffect} from "react";
import "./HomeArticles.css";
import  {Link} from "react-router-dom"

function HomeArticles() {

  return (
    <div className="articles-cnt-main">
      <div className="article-title"> <h2>Articles</h2></div>
      <div className="article-btns">
     

        <div className="art-btn-item">
          <Link to ="/cloud-integration" className="art-card art-card1" href="#">
            <h3>Cloud Integrations</h3>
            <p className="art-small">
            The cloud revolution has transformed how businesses operate. <b>Read more..</b>
            </p>
            <div className="go-corner" href="#">
              <div className="go-arrow">→</div>
            </div>
          </Link>
        </div>
        <div className="art-btn-item">
          <Link  to="./testing" className="art-card art-card1" href="#">
            <h3>Industry 4.0</h3>
            <p className="art-small">
            The manufacturing industry is experiencing a revolution known as <b>Read more..</b>
            </p>
            <div className="go-corner" href="#">
              <div className="go-arrow">→</div>
            </div>
          </Link>
        </div>

        <div className="art-btn-item">
          <Link to="/erp-crm-symphony" className="art-card art-card1" href="#">
            <h3>ERP-CRM Symphony</h3>
            <p className="art-small">
            In today's experience-driven economy, customer satisfaction <b>Read more..</b>
            </p>
            <div className="go-corner" href="#">
              <div className="go-arrow">→</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomeArticles;
