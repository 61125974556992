

// import React, { useState, useEffect } from "react";
// import { FaBars, FaTimes } from "react-icons/fa";
// import { Link, useLocation } from "react-router-dom";
// // import Logo from "../../assets/supernal-logo-black.svg";
// import Logo from "../../assets/LogoSup.png";
// import "./Navbar.css";

// const Navbar = () => {
//   const [click, setClick] = useState(false);
//   const handleClick = () => setClick(!click);
//   const closeMenu = () => setClick(false);
//   const location = useLocation();
//   const [color, setColor] = useState(false);
//   const [logobg, setLogobg] = useState(false);
//   const [linkcolor, setLinkcolor] = useState(false);

//   const changeBackground = () => {
//     if (window.scrollY >= 80) {
//       setColor(true);
//       setLogobg(true);
//       setLinkcolor(true);
//     } else {
//       setColor(false);
//       setLogobg(false);
//       setLinkcolor(false);
//     }
//   };

//   useEffect(() => {
//     if (location.pathname === "/") {
//       window.addEventListener("scroll", changeBackground);
//     } else {
//       setColor(true); // Ensure background is set for non-home pages
//       setLogobg(true); 
//       setLinkcolor(true);
//     }
//     return () => window.removeEventListener("scroll", changeBackground);
//   }, [location.pathname]);

//   return (
//     <>
//       <div className={color ? "header header-bg" : "header"}>
//         <nav className="navbar">
//           <a href="/" className="logo">
//             <img
//               src={Logo}
//               alt="logo"
//               className={logobg ? "supernal-logo supernal-logo-active" : "supernal-logo"}
//             />
//           </a>
//           <div className="hamburger" onClick={handleClick}>
//             {click ? (
//               <FaTimes size={30} style={{ color: "#ffffff" }} />
//             ) : (
//               <FaBars size={30} style={{ color: "#ffffff" }} />
//             )}
//           </div>
//           <div className={click ? "nav-menu active" : "nav-menu hide-on-mobile"}>
//             <li className={location.pathname === "/" ? "active3" : "nav-item "}>
//               <Link to="/" onClick={closeMenu} className={linkcolor ? "nav-link-su nav-link-su active" : "nav-link-su"}>
//                 Home
//               </Link>
//             </li>
//             <li className={location.pathname === "/studios" ? "active3" : "nav-item "}>
//               <Link to="/studios" onClick={closeMenu} className={linkcolor ? "nav-link-su nav-link-su active" : "nav-link-su"}>
//                 Studios
//               </Link>
//             </li>
//             <li className={location.pathname === "/industries-pages" ? "active3" : "nav-item "}>
//               <Link to="/industries-pages" onClick={closeMenu} className={linkcolor ? "nav-link-su nav-link-su active" : "nav-link-su"}>
//                 Industries
//               </Link>
//             </li>
//             <li className={location.pathname === "/about-us" ? "active3" : "nav-item "}>
//               <Link to="/about-us" onClick={closeMenu} className={linkcolor ? "nav-link-su nav-link-su active" : "nav-link-su"}>
//                 About Us
//               </Link>
//             </li>
//             <li className={location.pathname === "/careers" ? "active3" : "nav-item "}>
//               <Link to="https://supernal-it.com/careers/" onClick={closeMenu} className={linkcolor ? "nav-link-su nav-link-su active" : "nav-link-su"}>
//            Careers
//               </Link>
//             </li>
//             <Link className="nav-item " to="/contact-us">
//               <button className="CaButton" >
//                 Connect
//               </button>
//             </Link>
//           </div>
//         </nav>
//       </div>
//     </>
//   );
// };

// export default Navbar;



// import React, { useState, useEffect, useRef } from 'react';
// import { useLocation, Link } from 'react-router-dom';
// import './Navbar.css';
// import Logo from "../../assets/LogoSup.png";
 
// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [activeSubMenu, setActiveSubMenu] = useState(null);
//   const [scrolled, setScrolled] = useState(false);
//   const [linkColor, setLinkColor] = useState(false);
//   const [logobg, setLogobg] = useState(false);
//   const navbarRef = useRef();
//   const location = useLocation();
 
//   const isHomePage = location.pathname === '/';
 
//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//     document.body.classList.toggle('no-scroll', !isOpen);
//   };
 
//   const closeMenu = () => {
//     setIsOpen(false);
//     document.body.classList.remove('no-scroll');
//   };
 
//   const toggleSubMenu = (index) => {
//     setActiveSubMenu(activeSubMenu === index ? null : index);
//   };
 
//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 80) {
//         setScrolled(true);
//         setLinkColor(true);
//         setLogobg(true)
//       } else {
//         setScrolled(false);
//         setLinkColor(false);
//         setLogobg(false)
//       }
//     };
 
//     if (!isHomePage) {
//       setScrolled(true); // Set default state for non-home pages
//       setLinkColor(true);
//     } else {
//       window.addEventListener('scroll', handleScroll);
//     }
 
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, [isHomePage]);
 
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (navbarRef.current && !navbarRef.current.contains(event.target)) {
//         setActiveSubMenu(null);
//       }
//     };
 
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, []);
 
//   return (
//     <>
//       <nav ref={navbarRef} className={`navbar ${scrolled ? 'scrolled' : ''}`}>
//         <Link to="/" className="navbar-logo">
//         <img
//               src={Logo}
//               alt="logo"
          
//               className={`supernal-logo ${logobg && !isOpen ? 'supernal-logo-active' : 'supernal-logo-active'}`}
//             />
//         </Link>
//         <div className="hamburger" onClick={toggleMenu}>
//           &#9776;
//         </div>
//         <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
//           <div className="close-btn" onClick={toggleMenu}>&times;</div>
//           <Link to="/" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={closeMenu}>Home</Link>
//           <Link to="/studios" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={closeMenu}>
//             Studios
//             {/* <div className={`sub-menu ${activeSubMenu === 1 ? 'open' : ''}`}>
//               <Link to="" onClick={closeMenu}>XD</Link>
//               <Link to="" onClick={closeMenu}>CA</Link>
//               <Link to="" onClick={closeMenu}>WE</Link>
//               <Link to="" onClick={closeMenu}>W3</Link>
//               <Link to="" onClick={closeMenu}>Dc</Link>
//             </div> */}
//           </Link>
//           <Link to="/industries" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={() => toggleSubMenu(2)}>
//             Industries
//             <div className={`sub-menu ${activeSubMenu === 2 ? 'open' : ''}`}>
//               <Link to="/banking&finance" onClick={closeMenu}>Banking & Finance</Link>
//               <Link to="/e-commerce&quick-commerce" onClick={closeMenu}>E-com & Q-com</Link>
//               <Link to="/health&fitness" onClick={closeMenu}>Health & Fitness</Link>
//               <Link to="/entertainment&sports" onClick={closeMenu}>Entertainment & Sports</Link>
//               <Link to="/manufacturing" onClick={closeMenu}>Manufacturing</Link>
//             </div>
//           </Link>
//           <Link to="/about-us" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={closeMenu}>About Us</Link>
//           <Link to="https://supernal-it.com/careers/" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={closeMenu}>Careers</Link>
//           <Link className="nav-item " to="/contact-us">
//               <button className="CaButton" >
//                 Connect
//               </button>
//             </Link>
//         </div>
//       </nav>
//       <div className={`overlay-burger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}></div>
//     </>
//   );
// };
 
// export default Navbar;
 

import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Navbar.css';
import Logo from "../../assets/LogoSup.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [linkColor, setLinkColor] = useState(false);
  const [logobg, setLogobg] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial state for mobile detection
  const navbarRef = useRef();
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.classList.toggle('no-scroll', !isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.classList.remove('no-scroll');
  };

  const toggleSubMenu = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScrolled(true);
        setLinkColor(true);
        setLogobg(true);
      } else {
        setScrolled(false);
        setLinkColor(false);
        setLogobg(false);
      }
    };

    if (!isHomePage) {
      setScrolled(true); // Set default state for non-home pages
      setLinkColor(true);
    } else {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isHomePage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setActiveSubMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <nav ref={navbarRef} className={`navbar ${scrolled ? 'scrolled' : ''}`}>
        <Link to="/" className="navbar-logo">
          <img
            src={Logo}
            alt="logo"
            className={`supernal-logo ${logobg && !isOpen ? 'supernal-logo-active' : 'supernal-logo-active'}`}
          />
        </Link>
        <div className="hamburger" onClick={toggleMenu}>
          &#9776;
        </div>
        <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
          <div className="close-btn" onClick={toggleMenu}>&times;</div>
          <Link to="/" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={closeMenu}>Home</Link>
          <Link to="/studios" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={closeMenu}>
            Studios
          </Link>
          {isMobile ? (
            <div className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={() => toggleSubMenu(2)}>
              Industries
              <div className={`sub-menu ${activeSubMenu === 2 ? 'open' : ''}`}>
                <Link to="/banking&finance" onClick={closeMenu}>Banking & Finance</Link>
                <Link to="/e-commerce&quick-commerce" onClick={closeMenu}>E-com & Q-com</Link>
                <Link to="/health&fitness" onClick={closeMenu}>Health & Fitness</Link>
                <Link to="/entertainment&sports" onClick={closeMenu}>Entertainment & Sports</Link>
                <Link to="/manufacturing" onClick={closeMenu}>Manufacturing</Link>
              </div>
            </div>
          ) : (
            <Link to="/industries" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={() => toggleSubMenu(2)}>
              Industries
              <div className={`sub-menu ${activeSubMenu === 2 ? 'open' : ''}`}>
                <Link to="/banking&finance" onClick={closeMenu}>Banking & Finance</Link>
                <Link to="/e-commerce&quick-commerce" onClick={closeMenu}>E-com & Q-com</Link>
                <Link to="/health&fitness" onClick={closeMenu}>Health & Fitness</Link>
                <Link to="/entertainment&sports" onClick={closeMenu}>Entertainment & Sports</Link>
                <Link to="/manufacturing" onClick={closeMenu}>Manufacturing</Link>
              </div>
            </Link>
          )}
          <Link to="/about-us" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={closeMenu}>About Us</Link>
          <a href="https://supernal-it.com/careers/" className={`navbar-item ${linkColor && !isOpen ? 'color-change-link' : ''}`} onClick={closeMenu}>Careers</a>
          <Link className="nav-item" to="/contact-us" onClick={closeMenu}>
            <button className="CaButton">
              Connect
            </button>
          </Link>
        </div>
      </nav>
      <div className={`overlay-burger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}></div>
    </>
  );
};

export default Navbar;
