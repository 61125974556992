import React from 'react'
import "./MobileApps.css"
import ReactNative_logo from "../../../assets/ReactNative-logo-03.png"
import Flutter_logo from "../../../assets/flutter-logo-03.png"
import Android_logo from "../../../assets/Android-logo.png"
import Ios_logo from "../../../assets/iOS-logo.png"

function MobileApps() {
  return (

    <> 
        {/* ----------------Native app development---------------------------------------------- */}
    <div className='Heading-mad-native'>
    <div className='heading-hybrid-apps'>

      <h1>Native App Development </h1>
      </div>
    </div>
    <div className='Mobile-App-Tech-Bg'>
      <div className='MAT-Rnative-Img'>
        <img src={Android_logo} alt=""/>

      </div>
      <div className='MAT-Rnative-Text'>
        <p>
        We create fully functional & stable apps for a wide range of Android device types.
        </p>
        <p>
         As an Android app development company, our highly skilled team has the know-how of
          the Android ecosystem. With experience, we can develop custom
           Android solutions as per your business needs.
           </p>
           <p>
           For native app development, we utilize Android SDK, Kotlin, and Java.
            Our team has proficiency in utilizing cross-platform technologies like React Native, and Flutter to create applications 
           that work on multiple platforms. Our cross-platform app developers can help you migrate mobile apps developed with other technologies such as Kotlin [Java], and Swift to Android.
           </p>
           <p>
           We have developed feature-rich Android apps across many industries like Fintech, Ecommerce Media & Entertainment.
        </p>
      </div>

    </div>

    {/* -------------------------------------- */}

    <div className='Mobile-App-Tech-Flutter-Bg'>

      <div className='MAT-Flutter-Text'>
        <p>
          With our rich expertise in custom iOS application development, we build engaging, & consistent apps for your industry.
          Our iOS app developers work with you at every step of the way, from solution design to delivery and maintenance.
          Regardless of its complexity, we ensure that your app is compatible with every iOS device while focusing on scalability,
           codebase performance, functional design & seamless navigation.
           </p>
           <p>

        Our team is well-versed with specific design and development guidelines for all iPhone device versions.
         Our team has a strong background in full-stack iOS development, iOS SDK frameworks, Objective C, Xcode IDE, Cocoa,
          Cocoa Touch and Cocoa Touch libraries. We are familiar with utilizing localization & location API, database API,
           Safari Web Kit Extensions, and other tools for iPhone development, 
           and multi-threading on mobile and tablet devices. We support your existing iOS apps through 
           software integrations,
           </p>
           <p>
           personalized modules, and custom features to enhance functionality,
           aesthetics, and the overall user experience.
          Our QA specialists make sure that your application is thoroughly tested at 
          every stage of the development process.
          performance optimization & maintenance post-deployment.

        </p>
      </div>

      <div className='MAT-Flutter-Img'>
        <img src={Ios_logo} alt="flutter-logo"/>

      </div>

    </div>



    {/* ----------------hybrid app development--------------------------------- */}

    <div className='Heading-mad-native'>
      <div className='heading-hybrid-apps'>
      <h1>Hybrid App Development </h1>
      </div>
    </div>
     <div className='Mobile-App-Tech-Bg'>
      <div className='MAT-Rnative-Img'>
        <img src={ReactNative_logo} alt=""/>

      </div>
      <div className='MAT-Rnative-Text'>
        <p>
        Developing responsive and interactive cross-platform app’s
        </p>
        <p>  
        You already know all about our iOS and Android capabilities. In addition to
        these two popular platforms, Supernal Infotech is also skilled in React Native
        development services, which uses the JavaScript web framework for building
        multi-platform and cross platform apps.
        </p>
        <p>
        Through various use cases, React Native is now considered one of the best
        frameworks for building hybrid mobile apps and many major companies like
        Airbnb, Tesla, and Facebook, are using this platform for their cross-platform
        apps.
        </p>
        <p>
         The platform is a cost-effective and convenient solution since it leverages a
        single codebase for developing Android and iOS applications, ensuring
        seamless integration with their existing mobile app development team &amp;
        resources. The code can also be reused easily, thus helping your app develop
        and upgrade faster.
        </p>
        <p>
        Other major benefits of React Native include support for third-party plug-ins
        and the ability to develop better user interfaces.

        </p>
      </div>

    </div>

    {/* -------------------------------------- */}

    <div className='Mobile-App-Tech-Flutter-Bg'>

      <div className='MAT-Flutter-Text'>
        <p>
         Enhanced cross platform apps for multi-screen access
        </p>
        <p>
        Supernal Infotech is an expert in Flutter app development and we have all
          the necessary resources to offer you platform-independent apps with
          excellent user interface and optimal access through various screen. Flutter
          is Google’s UI toolkit capable of developing expressive interfaces featuring
          entirely customisable widgets.
          </p>
          <p>
          Our Flutter based app development solutions focus on all aspects of your
          app, from the design, layout, and interactivity to quick programming and
          powerful performance. Our array of Flutter services consist of cross-
          platform app development, upgrade and migration of legacy apps, testing
          and quality assurance as well as maintenance and support.
             </p>
      </div>

      <div className='MAT-Flutter-Img'>
        <img src={Flutter_logo} alt="flutter-logo"/>

      </div>

    </div>
 </>


  )
}

export default MobileApps