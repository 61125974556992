// // working code ;

// import React, { useEffect, useRef } from 'react';
// import './KeyFour.css'; // Import the CSS file

// const CardStack = () => {

//   const cards = [
//     {
//       title: "Advanced Technologies",
//       text: "Our Digital Studios at Supernal, staffed with seasoned professionals across diverse industries, offer specialized expertise and cutting-edge technologies for efficient project management. With our advanced infrastructure and security measures, we guarantee the success and safety of your project. We are well-versed in the latest technologies, which are essential to our daily operations. Trust that you are in capable hands when it comes to embracing innovative technologies."
//     },
//     {
//       title: "Effective Communication",
//       text: "Effective communication is at the heart of our Core Values. We maintain clear and open channels, providing regular updates and actively seeking your feedback, we build trust with our customer. Our collaborative approach ensures cultural compatibility and seamless integration with your team, fostering a strong and reliable partnership."
//     },
//     {
//       title: "Commitment to Quality",
//       text: "We prioritize quality in every aspect of our work. With rigorous quality assurance processes and a commitment to excellence, our Digital Studios utilize agile methodologies to ensure the reliable delivery of high-standard products and services, backed by positive client testimonials and a proven track record."
//     },
//     {
//       title: "Innovative Solutions",
//       text: "Innovation fuels our Digital Studios at Supernal Infotech, providing cutting-edge solutions tailored to your needs. Our flexible and proactive teams adapt to changing requirements, offering scalability and flexibility for your project. Our team structures accommodate diverse time zones seamlessly for resource scaling."
//     },
//     {
//       title: "Post-Development Support",
//       text: "Our commitment to your success extends beyond development. We offer comprehensive post-development support and maintenance, providing ongoing technical support, regular updates, and upgrades to keep your product current and fully functional."
//     },
//   ];

//   const colors = ['#F95933', '#6441A0', '#7DC85C', '#00AECF', '#000000'];

//   return (
//     <>
//       <div className='one'>
//         <main>
//           <h1 className='title'>Our Competitive Edge</h1>
//           <div id="stack">
//             {cards.map((card, index) => (
//               <div
//                 key={index}
//                 className={`card card-${index % 4}`} // Add a class based on index
//               // Assign ref to each card
//               >
//                 <h2 className='card-title' style={{ color: colors[index % colors.length] }}>
//                   {card.title}
//                 </h2> {/* Card title */}
//                 <p className='card-text'>{card.text}</p> {/* Card text */}
//               </div>
//             ))}
//           </div>
//         </main>
//       </div>
//     </>
//   );
// };

// export default CardStack;

// // testing code

// import React, { useRef, useState } from "react";

// import { Swiper, SwiperSlide } from "swiper/react";

// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

// import "./KeyFour.css";

// import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";

// export default function App() {
//   const cards = [
//     {
//       title: "Advanced Technologies",
//       text: "Our Digital Studios at Supernal, staffed with seasoned professionals across diverse industries, offer specialized expertise and cutting-edge technologies for efficient project management. With our advanced infrastructure and security measures, we guarantee the success and safety of your project. We are well-versed in the latest technologies, which are essential to our daily operations. Trust that you are in capable hands when it comes to embracing innovative technologies.",
//     },
//     {
//       title: "Effective Communication",
//       text: "Effective communication is at the heart of our Core Values. We maintain clear and open channels, providing regular updates and actively seeking your feedback, we build trust with our customer. Our collaborative approach ensures cultural compatibility and seamless integration with your team, fostering a strong and reliable partnership.",
//     },
//     {
//       title: "Commitment to Quality",
//       text: "We prioritize quality in every aspect of our work. With rigorous quality assurance processes and a commitment to excellence, our Digital Studios utilize agile methodologies to ensure the reliable delivery of high-standard products and services, backed by positive client testimonials and a proven track record.",
//     },
//     {
//       title: "Innovative Solutions",
//       text: "Innovation fuels our Digital Studios at Supernal Infotech, providing cutting-edge solutions tailored to your needs. Our flexible and proactive teams adapt to changing requirements, offering scalability and flexibility for your project. Our team structures accommodate diverse time zones seamlessly for resource scaling.",
//     },
//     {
//       title: "Post-Development Support",
//       text: "Our commitment to your success extends beyond development. We offer comprehensive post-development support and maintenance, providing ongoing technical support, regular updates, and upgrades to keep your product current and fully functional.",
//     },
//   ];
//   const colors = ["#F95933", "#6441A0", "#7DC85C", "#00AECF", "#000000"];

//   return (
//     <>
//       <Swiper
//         cssMode={true}
//         navigation={true}
//         pagination={true}
//         mousewheel={true}
//         keyboard={true}
//         modules={[Navigation, Pagination, Mousewheel, Keyboard]}
//         className="mySwiper"
//       >
//         <SwiperSlide>
//           <div
//             className="card card-1"
//           >
//             <h2
//               className="card-title"
//               style={{ color: "#F95933"}}
//             >
//               Advanced Technologies
//             </h2>

//             <p className="card-text">
//               Our Digital Studios at Supernal, staffed with seasoned
//               professionals across diverse industries, offer specialized
//               expertise and cutting-edge technologies for efficient project
//               management. With our advanced infrastructure and security
//               measures, we guarantee the success and safety of your project. We
//               are well-versed in the latest technologies, which are essential to
//               our daily operations. Trust that you are in capable hands when it
//               comes to embracing innovative technologies.
//             </p>

//           </div>
//         </SwiperSlide>
//         <SwiperSlide>Slide 2</SwiperSlide>
//         <SwiperSlide>Slide 3</SwiperSlide>
//         <SwiperSlide>Slide 4</SwiperSlide>
//         <SwiperSlide>Slide 5</SwiperSlide>
//         <SwiperSlide>Slide 6</SwiperSlide>
//         <SwiperSlide>Slide 7</SwiperSlide>
//         <SwiperSlide>Slide 8</SwiperSlide>
//         <SwiperSlide>Slide 9</SwiperSlide>
//       </Swiper>
//     </>
//   );
// }

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeyFour.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function App() {
  return (
    <>
      <div className="compititive-head">
        <h1>Our Competitive Edge</h1>
      </div>

      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          {/* <div className="card card-1" style={{backgroundColor:"rgba(249, 89, 51, 0.02)" }}> */}
          <div className="card card-1" >
            <h2 className="card-title" style={{ color: "#F95933" }}>
              Advanced Technologies
            </h2>

            <p className="card-text">
              Our Digital Studios at Supernal, staffed with seasoned
              professionals across diverse industries, offer specialized
              expertise and cutting-edge technologies for efficient project
              management. With our advanced infrastructure and security
              measures, we guarantee the success and safety of your project. We
              are well-versed in the latest technologies, which are essential to
              our daily operations. Trust that you are in capable hands when it
              comes to embracing innovative technologies.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        {/* <div className="card card-1" style={{backgroundColor:"rgba(100, 65, 160, 0.02)" }}> */}
        <div className="card card-1" >
            <h2 className="card-title" style={{ color: "#6441A0" }}>
            Effective Communication
            </h2>

            <p className="card-text">
              Effective communication is at the heart of our Core Values. We
              maintain clear and open channels, providing regular updates and
              actively seeking your feedback, we build trust with our customer.
              Our collaborative approach ensures cultural compatibility and
              seamless integration with your team, fostering a strong and
              reliable partnership.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        {/* <div className="card card-1" style={{backgroundColor:"rgba(125, 200, 92, 0.02)" }}> */}
        <div className="card card-1" >
            <h2 className="card-title" style={{ color: "#7DC85C" }}>
            Commitment to Quality
            </h2>

            <p className="card-text">
              We prioritize quality in every aspect of our work. With rigorous
              quality assurance processes and a commitment to excellence, our
              Digital Studios utilize agile methodologies to ensure the reliable
              delivery of high-standard products and services, backed by
              positive client testimonials and a proven track record.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        {/* <div className="card card-1" style={{backgroundColor:"rgba(0, 174, 207, 0.02)" }}> */}
        <div className="card card-1" >
            <h2 className="card-title" style={{ color: "#00AECF" }}>
            Innovative Solutions
            </h2>

            <p className="card-text">
              Innovation fuels our Digital Studios at Supernal Infotech,
              providing cutting-edge solutions tailored to your needs. Our
              flexible and proactive teams adapt to changing requirements,
              offering scalability and flexibility for your project. Our team
              structures accommodate diverse time zones seamlessly for resource
              scaling.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        {/* <div className="card card-1" style={{backgroundColor:"rgba(0, 0, 0, 0.02)" }}> */}
        <div className="card card-1" >
            <h2 className="card-title" style={{ color: "#000000" }}>
            Post-Development Support
            </h2>

            <p className="card-text">
              Our commitment to your success extends beyond development. We
              offer comprehensive post-development support and maintenance,
              providing ongoing technical support, regular updates, and upgrades
              to keep your product current and fully functional.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
