import React from "react";
import "./Footer.css";
import Img from "../../assets/LogoSup.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer-section">
      <div className="container-footer">
        <div className="footer-content">
          <div className="footer-row">
            <div className="footer-cnt-1"> 
            <div className="footer-col footer-log-image-section">
              <div className="footer-widget">
                <div className="footer-logo">
             <h3 className="find-us-text">find us</h3>
                  <Link to="/">
                    <img src={Img} className="img-fluid" alt="logo" />
                  </Link>
                

                </div>
                <div className="single-cta">
                <i className="fas fa-map-marker-alt"></i>
                <div className="cta-text">
         
                  <span>
                    Plot No: 100,Srinivasam Complex,
                    <br />
                    2nd Floor Lumbini Avenue Gachibowli, <br />
                    Hyderabad Telangana, India
                  </span>
                </div>
              </div>


              </div>
            </div>
            <div className="footer-col">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Studios</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/experience-design">Experience Design</Link>
                  </li>
                  <li>
                    <Link to="/cloud-applications">Cloud Applications</Link>
                  </li>
                  <li>
                    <Link to="/workforce-engagement">Workforce Engagement</Link>
                  </li>
                  <li>
                    <Link to="/web-3">Web 3</Link>
                  </li>
                  <li>
                    <Link to="/data-centric">Data Centric</Link>
                  </li>
                 
                </ul>
              </div>
            </div>
            </div>
            <div className="footer-cnt-1"> 
            <div className="footer-col">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Industries</h3>
                </div>
                <ul>
                  <li>
                    <Link to="banking&finance">
                      Banking & Finance
                    </Link>
                  </li>
                  <li>
                    <Link to="e-commerce&quick-commerce">
                    E-com & Q-com
                    </Link>
                  </li>
                  <li>
                    <Link to="health&fitness">
                      Health & Fitness
                    </Link>
                  </li>
                  <li>
                    <Link to="entertainment&sports">
                    Entertainment&Sports
                    </Link>
                  </li>
              
                  <li>
                    <Link to="manufacturing">
                    Manufacturing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>


            <div className="footer-col">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Let's Talk</h3>
                </div>
                <div className="single-cta">
                <i className="fas fa-phone"></i>
                <div className="cta-text">
                
                  <span>🇮🇳 +91 9550008443</span>
                  <br />
                  <span>🇺🇸 +1-206-866-5159</span>
                </div>
              </div>
              <div className="single-cta">
                <i className="far fa-envelope-open"></i>
                <div className="cta-text">
                  <h4>Mail us</h4>
                  <span>connect@supernal-it.com</span>
                </div>
              </div>

              
                
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container-footer">
          <div className="footer-row">
            <div className="footer-col">
              <div className="copyright-text">
                <p>&copy; 2020-2024 Supernal Infotech Pvt. All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
