import React, { useEffect } from "react";
import "./Entertainment .css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import image1 from "../../../assets/IndustriesAssets/sportsMbApp.webp";
import image2 from "../../../assets/IndustriesAssets/cusSOLSports.webp";
import image3 from "../../../assets/IndustriesAssets/AnalyticsSports.webp";
import image4 from "../../../assets/IndustriesAssets/AnalyticsSports.webp";

import EducationConnectus from "../../../components/ConnectWithUs/EducationConnectus/Eductaion.jsx";

gsap.registerPlugin(ScrollTrigger);
function Education() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const items = document.querySelectorAll(".industry__item");

    items.forEach((item) => {
      const itemTitle = item.querySelector(".industry__item__title");
      const itemImg = item.querySelector(".industry__item img");
      const headings = itemTitle.querySelectorAll("h1, h2, p");

      const itemTL = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: "top 100%",
          end: "bottom 55%",
          scrub: 3,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          // markers: true,
        },
      });

      itemTL
        .fromTo(
          itemTitle,
          { opacity: 0, y: "20%" },
          { opacity: 1, y: "0%", ease: "power2.inOut", duration: 1.5 },
          0
        )
        .fromTo(
          headings,
          { opacity: 0, y: "10%" },
          {
            opacity: 1,
            y: "0%",
            ease: "power2.inOut",
            stagger: 0.3,
            duration: 1,
          },
          0
        )
        .fromTo(
          itemImg,
          { scale: 0, x: "0vw", y: "50vh", rotate: -30 },
          {
            scale: 1.2,
            x: "-10vw",
            y: "0vh",
            rotate: 0,
            ease: "power2.inOut",
            duration: 15,
          },
          "+=15"
        )
        .to(
          itemImg,
          {
            scale: 0,
            x: "50vw",
            y: "-50vh",
            rotate: 30,
            ease: "power2.inOut",
            duration: 15,
          },
          "+=15"
        );
    });
  }, []);

  return (
    <>
      <div className="education-section">
        <div className="education-header">
          <h1>Tailored Solutions for the Entertainment and Sports Sector</h1>
          <p>
            At Supernal Infotech, we’re passionate about crafting personalized
            technology solutions that cater specifically to the vibrant world of
            entertainment and sports. Our expertise in mobile applications,
            custom solutions, and advanced analytics empowers you to boost fan
            engagement, streamline operations, and harness data for smarter
            decision-making. Let’s elevate your game together!
          </p>
        </div>
      </div>

      {/* ----------- */}

      

      {/* ----------- */}

      <div className="industries__container">
        <div className="industries__list">
          <div className="industry__item">
            <div className="industry__item__title">
              <h1> MOBILE APPLICATIONS</h1>
              <h2>ENGAGING AND INTUITIVE APPS FOR FANS AND AUDIENCES</h2>
              <p>
                In the vibrant world of entertainment and sports, mobile
                applications are the heartbeat of fan connection! We create
                custom apps that offer an immersive and engaging
                experience—whether you're streaming live events, diving into
                exclusive content, or interacting with your favorite teams and
                celebrities, we’ve got you covered! Join us in transforming how
                fans experience the excitement!
              </p>
            </div>
            <img src={image1} alt="Item 1" />
          </div>

          <div className="industry__item">
            <div className="industry__item__title">
              <h1>Live Streaming and On-Demand Content</h1>
              {/* <h2>ENHANCING OPERATIONAL EFFICIENCY</h2> */}
              <p>
                Bring the action to life with high-quality live streaming and
                on-demand content straight to mobile devices, ensuring fans
                catch every thrilling moment.
              </p>
              <p><b>Interactive Features</b></p>
              <p>
                Boost fan engagement by adding fun interactive elements like
                live polls, quizzes, and social media integration—let your
                audience be part of the experience!
              </p>
              <p><b>Ticketing and Merchandise:</b></p>
              <p>
                Make ticket buying and merchandise shopping a breeze with
                user-friendly interfaces and secure payment options—it's never
                been easier to gear up for the excitement!
              </p>
              <p><b>Personalized Content:</b></p>
              <p>
                Harness the power of AI to deliver tailored content
                recommendations that match user preferences and viewing history,
                creating a unique experience for every fan!
              </p>
            </div>
            <img src={image2} alt="Item 1" />
          </div>

          <div className="industry__item">
            <div className="industry__item__title">
              <h1>CUSTOM SOLUTIONS</h1>
              <h2> TAILORED TECHNOLOGY FOR YOUR UNIQUE BUSINESS NEEDS</h2>
              <p>
                Every entertainment and sports organization has its own set of
                challenges and aspirations. That’s why our custom solutions are
                crafted just for you! We empower you with the tools you need to
                thrive in this competitive landscape, helping you tackle your
                specific hurdles and achieve your goals with confidence. Let’s
                elevate your game together!
              </p>
              <p><b>Event Management Systems:</b></p>
              <p>
                Transform your event planning experience with integrated systems
                that seamlessly handle everything from scheduling to logistics,
                making execution a breeze!
              </p>
              <p><b>Fan Engagement Platforms:</b></p>
              <p>
                Build vibrant communities with platforms that spark interaction
                and deepen fans' connections to their favorite teams, artists,
                or events.
              </p>
              <p><b>Content Management Systems:</b></p>
              <p>
                Take control of your digital content with powerful systems that
                efficiently manage and distribute across multiple platforms,
                ensuring quality and consistency every step of the way.
              </p>
              <p><b>Augmented and Virtual Reality:</b></p>
              <p>
                Dive into the future with immersive AR and VR experiences,
                offering fans an exciting and unique way to engage with sports
                and entertainment like never before!
              </p>
            </div>
            <img src={image3} alt="Item 1" />
          </div>

          <div className="industry__item" style={{marginBottom:"5vw"}}>
            <div className="industry__item__title">
              <h1>    ANALYTICS  </h1>
              <h2>UNLOCKING THE POWER OF DATA FOR STRATEGIC SUCCESS</h2>
              <p>
                  In the thrilling world of entertainment and sports, data is
                  your secret weapon! Our cutting-edge analytics solutions
                  empower you to make savvy decisions, streamline operations,
                  and elevate fan experiences to new heights. Let’s turn
                  insights into action!
                </p>
                <p><b>Fan Behavior Analytics:</b></p>
                <p>
                      Unlock the secrets of fan behavior and preferences to
                      craft marketing strategies and content that truly
                      resonate!
                    </p>
                    <p><b>Performance Analytics:</b></p>
                    <p>
                      Harness the power of data to elevate player or performer
                      performance, enhancing training and development like never
                      before.
                    </p>
                    <p><b>Operational Efficiency:</b></p>
                    <p>
                      Discover untapped potential in event management, ticket
                      sales, and other operations to streamline and enhance your
                      processes.
                    </p>
                    <p><b>Evenue Optimization:</b></p>
                    <p>
                      Dive deep into revenue streams and uncover exciting
                      opportunities for growth and monetization!
                    </p>

            </div>
            <img src={image4} alt="Item 1" />
          </div>
        </div>
      </div>

      <EducationConnectus />
    </>
  );
}

export default Education;
