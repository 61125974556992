import React, { useEffect } from "react";
import "./Manufacturing.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import image1 from "../../../assets/IndustriesAssets/ManuCUTTINGEDGE.webp";
import image2 from "../../../assets/IndustriesAssets/ManuINCREASEEFFICIENCY.webp";
import image3 from "../../../assets/IndustriesAssets/ManuIMPROVEDQUALITY.webp";
import image4 from "../../../assets/IndustriesAssets/ManuBETTERDECISION.webp";
import ConnectWithUs from "../../../components/ConnectWithUs/ConnectWithUs"




gsap.registerPlugin(ScrollTrigger);
function Manufacturing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const items = document.querySelectorAll(".industry__item");

    items.forEach((item) => {
      const itemTitle = item.querySelector(".industry__item__title");
      const itemImg = item.querySelector(".industry__item img");
  

      const itemTL = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: "top 100%",
          end: "bottom 55%",
          scrub: 3,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          // markers: true,
        },
      });

      itemTL
        .fromTo(
          itemTitle,
          { opacity: 0, y: "20%" },
          { opacity: 1, y: "0%", ease: "power2.inOut", duration: 1.5 },
          0
        )

        .fromTo(
          itemImg,
          { scale: 0, x: "0vw", y: "50vh", rotate: -30 },
          {
            scale: 1.2,
            x: "-10vw",
            y: "0vh",
            rotate: 0,
            ease: "power2.inOut",
            duration: 15,
          },
          "+=15"
        )
        .to(
          itemImg,
          {
            scale: 0,
            x: "50vw",
            y: "-50vh",
            rotate: 30,
            ease: "power2.inOut",
            duration: 15,
          },
          "+=15"
        );
    });
  }, []);

  return (
    <>
      <div className="manufacturing-section">
        <div className="manufacturing-header">
          <h1>Manufacturing Industry Solutions</h1>
          <p>
            At Supernal, we specialize in providing cutting-edge solutions for
            the manufacturing industry. Our expertise and innovative
            technologies are designed to enhance efficiency, streamline
            operations, and drive growth for your business. Here’s how we can
            help in key areas:
          </p>
        </div>
      </div>


     


      <div className="industries__container">
        <div className="industries__list">
          <div className="industry__item">
            <div className="industry__item__title" >
              <h1> CUTTING-EDGE SOLUTIONS TRANSFORMING THE MANUFACTURING INDUSTRY</h1>
              <h2>  ENHANCING EFFICIENCY AND PRODUCTIVITY WITH INNOVATIVE
              SOLUTIONS</h2>
              <p>
                  At Supernal, we understand that no two manufacturing
                  operations are identical, which is why we offer enhanced
                  flexibility and customization solutions tailored to your
                  specific needs. Through our custom applications, we develop
                  bespoke software tailored to address your unique operational
                  challenges and requirements. Additionally, our expertise in
                  SaaS application customization allows us to modify and extend
                  existing software tools to better fit your workflows and
                  business processes. This dual approach ensures that you can
                  leverage technology that not only aligns with your current
                  needs but also adapts to evolving demands as your business
                  grows.
                </p>
            </div>
            <img src={image1} alt="Item 1" />
          </div>

          <div className="industry__item">
            <div className="industry__item__title">
              <h1> INCREASE EFFICIENCY AND PRODUCTIVITY BY INTEGRATIONS AND </h1>
              <h2>AUTOMATIONS</h2>
              <p>
                  Integrating and automating processes within your manufacturing
                  setup can significantly boost efficiency and productivity. Our
                  advanced integration solutions ensure seamless communication
                  between various systems, eliminating bottlenecks and reducing
                  manual intervention. Automation technologies, such as robotic
                  process automation (RPA) and automated workflows, enable
                  faster production cycles, consistent quality, and optimal
                  resource utilization. By implementing these innovative tools,
                  your business can achieve higher outputs with fewer errors,
                  ultimately leading to increased profitability and a
                  competitive edge in the market.
                </p>
            </div>
            <img src={image2} alt="Item 1" />
          </div>


          <div className="industry__item">
            <div className="industry__item__title">
              <h1> IMPROVED QUALITY AND</h1>
              <h2>REDUCED WASTE</h2>
              <p>
                  At Supernal, we are committed to helping manufacturers improve
                  product quality and reduce waste through intelligent
                  solutions. By utilizing advanced data analytics and machine
                  learning algorithms, we can identify patterns and predict
                  potential issues before they arise, ensuring that production
                  processes remain consistent and reliable. Our quality control
                  systems include real-time monitoring and feedback loops that
                  enable you to detect defects early and make necessary
                  adjustments immediately. This proactive approach not only
                  preserves resources but also minimizes rework and scrap,
                  leading to significant cost savings. Additionally, our
                  sustainable manufacturing practices aim to optimize material
                  usage and reduce environmental impact, aligning with global
                  sustainability goals. With our comprehensive solutions,
                  manufacturers can achieve superior product quality, enhanced
                  customer satisfaction, and a more sustainable operation.
                </p>
            </div>
            <img src={image3} alt="Item 1" />
          </div>


          <div className="industry__item">
            <div className="industry__item__title">
              <h1>BETTER DECISION MAKING THROUGH </h1>
              <h2>DATA ANALYTICS</h2>
              <p>
                  In today's data-driven world, leveraging data analytics is
                  crucial for making informed and strategic decisions in the
                  manufacturing industry. At Supernal, we provide advanced data
                  analytics solutions to help you gain critical insights into
                  your operations. By collecting and analyzing data from various
                  sources, such as production lines, inventory systems, and
                  supply chains, our tools enable you to identify trends,
                  predict demand, and optimize resource allocation. Our data
                  visualization platforms present complex data in an easily
                  understandable format, empowering your team to make quick and
                  accurate decisions. Furthermore, with predictive analytics,
                  you can foresee potential issues and mitigate risks before
                  they impact your business. Embracing data analytics not only
                  enhances operational efficiency but also drives innovation and
                  growth, giving you a competitive advantage in the
                  ever-evolving manufacturing landscape.
                </p>
                <p >
                  Partner with Supernal to transform your manufacturing
                  operations. Our industry-specific expertise and solutions are
                  designed to propel your business towards greater success.
                  Contact us today to learn more about how we can help you
                  achieve your manufacturing goals.
                </p>
            </div>
            <img src={image4} alt="Item 1" />
          </div>
      
        </div>
      </div>

<ConnectWithUs/>

    </>
  );
}

export default Manufacturing;
