// import React from 'react';
// import { motion } from 'framer-motion';
// import Visualisation from "../../assets/Visualisation.svg";
// import Prescriptive from "../../assets/Prescriptive.svg";
// import Predictive from "../../assets/Predictive.svg";
// import Descriptive from "../../assets/Descriptive.svg";
// import "./Dc.css";

// const data = [
//   {
//     imgSrc: Visualisation,
//     title: "Descriptive Analytics",
//     text: "Understand what happened in the past by analyzing historical data to identify patterns, trends, and correlations."
//   },
//   {
//     imgSrc: Prescriptive,
//     title: "Predictive Analytics",
//     text: "Anticipate future outcomes and behaviors using machine learning algorithms to forecast trends and optimize strategies."
//   },
//   {
//     imgSrc: Predictive,
//     title: "Prescriptive Analytics",
//     text: "Get data-driven recommendations on the best course of action to take, using AI and optimization techniques."
//   },
//   {
//     imgSrc: Descriptive,
//     title: "Data Visualization",
//     text: "Transform complex data into visually appealing dashboards and reports for easier understanding and faster decision-making."
//   }
// ];

// const cardVariants = {
//   hidden: { opacity: 0, scale: 0.9 },
//   visible: { opacity: 1, scale: 1, transition: { duration: 0.6, ease: "easeOut" } }
// };

// function OurBusinessIntelligence() {
//   return (
//     <>
//       <div className="dc-main-head-text" style={{ background: "#eaeeef" }}>
//         <h1>Our Business Intelligence & Analytics Solutions</h1>
//         <p>Turn your data into actionable insights with our advanced analytics capabilities</p>
//       </div>
//       <div className="an-sol-main-cnt" style={{ background: "#eaeeef" }}>
//         {data.map((item, index) => (
//           <motion.div
//             className="an-sol-item-ept"
//             key={index}
//             variants={cardVariants}
//             initial="hidden"
//             animate="visible"
//             whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
//             whileTap={{ scale: 0.95 }}
//           >
//             <div className="an-sol-item-ept-img">
//               <img src={item.imgSrc} alt={item.title} />
//             </div>
//             <div className="an-sol-item-ept-txt">
//               <h3>{item.title}</h3>
//               <p>{item.text}</p>
//             </div>
//           </motion.div>
//         ))}
//       </div>
//     </>
//   );
// }

// export default OurBusinessIntelligence;


// import React from 'react';
// import { motion } from 'framer-motion';
// import { useInView } from 'react-intersection-observer';
// import Visualisation from "../../assets/Visualisation.svg";
// import Prescriptive from "../../assets/Prescriptive.svg";
// import Predictive from "../../assets/Predictive.svg";
// import Descriptive from "../../assets/Descriptive.svg";
// import "./Dc.css";

// const data = [
//   {
//     imgSrc: Visualisation,
//     title: "Descriptive Analytics",
//     text: "Understand what happened in the past by analyzing historical data to identify patterns, trends, and correlations."
//   },
//   {
//     imgSrc: Prescriptive,
//     title: "Predictive Analytics",
//     text: "Anticipate future outcomes and behaviors using machine learning algorithms to forecast trends and optimize strategies."
//   },
//   {
//     imgSrc: Predictive,
//     title: "Prescriptive Analytics",
//     text: "Get data-driven recommendations on the best course of action to take, using AI and optimization techniques."
//   },
//   {
//     imgSrc: Descriptive,
//     title: "Data Visualization",
//     text: "Transform complex data into visually appealing dashboards and reports for easier understanding and faster decision-making."
//   }
// ];

// const cardVariants = {
//   hidden: { opacity: 0, scale: 0.9 },
//   visible: { opacity: 1, scale: 1, transition: { duration: 0.9, ease: "easeOut" } }
// };

// const AnimatedCard = ({ item }) => {
//   const { ref, inView } = useInView({
//     triggerOnce: true,
//     threshold: 0.1
//   });

//   return (
//     <motion.div
//       className="an-sol-item-ept"
//       ref={ref}
//       variants={cardVariants}
//       initial="hidden"
//       animate={inView ? "visible" : "hidden"}
//       whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
//       whileTap={{ scale: 0.95 }}
//     >
//       <div className="an-sol-item-ept-img">
//         <img src={item.imgSrc} alt={item.title} />
//       </div>
//       <div className="an-sol-item-ept-txt">
//         <h3>{item.title}</h3>
//         <p>{item.text}</p>
//       </div>
//     </motion.div>
//   );
// };

// function OurBusinessIntelligence() {
//   return (
//     <>
//       <div className="dc-main-head-text" style={{ background: "#eaeeef" }}>
//         <h1>Our Business Intelligence & Analytics Solutions</h1>
//         <p>Turn your data into actionable insights with our advanced analytics capabilities</p>
//       </div>
//       <div className="an-sol-main-cnt" style={{ background: "#eaeeef" }}>
//         {data.map((item, index) => (
//           <AnimatedCard key={index} item={item} />
//         ))}
//       </div>
//     </>
//   );
// }

// export default OurBusinessIntelligence;


import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Visualisation from "../../assets/Visualisation.svg";
import Prescriptive from "../../assets/Prescriptive.svg";
import Predictive from "../../assets/Predictive.svg";
import Descriptive from "../../assets/Descriptive.svg";
import "./Dc.css";

const data = [
  {
    imgSrc: Visualisation,
    title: "Descriptive Analytics",
    text: "Understand what happened in the past by analyzing historical data to identify patterns, trends, and correlations."
  },
  {
    imgSrc: Prescriptive,
    title: "Predictive Analytics",
    text: "Anticipate future outcomes and behaviors using machine learning algorithms to forecast trends and optimize strategies."
  },
  {
    imgSrc: Predictive,
    title: "Prescriptive Analytics",
    text: "Get data-driven recommendations on the best course of action to take, using AI and optimization techniques."
  },
  {
    imgSrc: Descriptive,
    title: "Data Visualization",
    text: "Transform complex data into visually appealing dashboards and reports for easier understanding and faster decision-making."
  }
];

const cardVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.9, ease: "easeOut" } }
};

const AnimatedCard = ({ item }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Allow re-triggering
    threshold: 0.1
  });

  return (
    <motion.div
      className="an-sol-item-ept"
      ref={ref}
      variants={cardVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="an-sol-item-ept-img">
        <img src={item.imgSrc} alt={item.title} />
      </div>
      <div className="an-sol-item-ept-txt">
        <h3>{item.title}</h3>
        <p>{item.text}</p>
      </div>
    </motion.div>
  );
};

function OurBusinessIntelligence() {
  return (
    <>
      <div className="dc-main-head-text" style={{ background: "#eaeeef" }}>
        <h1>Our Business Intelligence & Analytics Solutions</h1>
        <p>Turn your data into actionable insights with our advanced analytics capabilities</p>
      </div>
      <div className="an-sol-main-cnt" style={{ background: "#eaeeef" }}>
        {data.map((item, index) => (
          <AnimatedCard key={index} item={item} />
        ))}
      </div>
    </>
  );
}

export default OurBusinessIntelligence;
