import React from "react";
import "./CoreTwo.css";
import Core1 from "../../../assets/CoreValues/Commitment.svg"
import Core2 from "../../../assets/CoreValues/ownership 1.svg"
import Core3 from "../../../assets/CoreValues/respect.svg"
import Core4 from "../../../assets/CoreValues/empowerment.svg"


function CoreTwo() {
  return (
    <div className="crv-main-cnt">
      <div className="crv-core-head">
        <h1 >Core Values</h1>
      </div>
      <div className="list-items-core"> 
      <div className="crv-core-items">
        <h5  style={{color:"#f95932"}}>COMMITMENT </h5>
        <img src={Core1}      alt=""/>
      </div>
      <div className="crv-core-items">
        <h5  style={{color:"#6441A0"}}>OWNERSHIP</h5>
        <img src={Core2}      alt=""/>
      </div>
      <div className="crv-core-items">
        <h5  style={{color:"#7DC85C"}}>RESPECT </h5>
        <img src={Core3}      alt=""/>
      </div>
      <div className="crv-core-items">
        <h5  style={{color:"#00AECF"}}>EMPOWERMENT </h5>
        <img src={Core4}     alt="" />
      </div>
     
      </div>
    </div>
  );
}

export default CoreTwo;
