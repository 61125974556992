
import React, { useRef, useState ,useEffect} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./IndustriesPages.css";

const DraggableMenuSlider = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const listRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const handleTabClick = (index, path) => {
    setActiveIndex(index);
    navigate(path);
  };

  return (
    <>
      <div className="indu-pages-section675555">
        <div className="container1234">
          <ul className="list" ref={listRef}>
            {[
              { name: "Banking & Finance", path: "banking&finance" },
              {
                name: "E-com & Q-com",
                path: "e-commerce&quick-commerce",
              },
              { name: "Health & Fitness", path: "health&fitness" },
              { name: "Entertainment & Sports", path: "entertainment&sports" },
              // { name: "Food & Beverages", path: "food&beverages" },
              { name: "Manufacturing", path: "manufacturing" },
              // { name: "Retail", path: "retail" },
              // { name: "QCOM", path: "qcom" },
            ].map((item, index) => (
              <li
                key={index}
                onClick={() => handleTabClick(index, item.path)}
                className={index === activeIndex ? "selected-list-item" : ""}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="">
        <Outlet />
      </div>
    </>
  );
};

export default DraggableMenuSlider;
