import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./CarouselHome.css";
import XD from "../../assets/StudiosHeroImages/XD83@2x.png";
import CA from "../../assets/StudiosHeroImages/CA.png";
import WE from "../../assets/StudiosHeroImages/WE86@2x.png";
import W3 from "../../assets/StudiosHeroImages/W387@2x.png";
import DC from "../../assets/StudiosHeroImages/DC84@2x.png";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const Scroll = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    gsap.set(".photo:not(:first-child)", {
      opacity: 1,
      scale: 1,
      y: "100%",
      clipPath: "inset(100% 0% 0%)",
    });

    gsap.set(".photo img", { y: 5 });

    const animation = gsap.to(".photo:not(:first-child)", {
      opacity: 1,
      scale: 1,
      duration: 1,
      stagger: 1,
      y: "0%",
      clipPath: "inset(0% 0% 0%)",
    });

    gsap.to(".photo img", {
      y: -5,
      duration: 1,
      repeat: 0,
      yoyo: true,
    });

    ScrollTrigger.create({
      trigger: ".gallery",
      start: "top top",
      end: "bottom bottom",
      pin: ".right",
      animation: animation,
      scrub: 2,
    });

    const sections = [
      { trigger: ".d1", color: "rgba(249, 89, 51, 0.9)" },
      { trigger: ".d2", color: "rgba(100, 65, 160, 0.9)" },
      { trigger: ".d3", color: "rgba(125, 200, 92, 0.9)" },
      { trigger: ".d4", color: "rgba(0, 174, 207, 0.9)" },
      { trigger: ".d5", color: "rgba(0, 0, 0, 0.9)" },
    ];

    sections.forEach((section, index) => {
      ScrollTrigger.create({
        trigger: section.trigger,
        start: "top 60%",
        end: "bottom bottom",
        onEnter: () => {
          gsap.to(containerRef.current, {
            backgroundColor: section.color,
            duration: 0.5,
          });
        },
        onLeaveBack: () => {
          gsap.to(containerRef.current, {
            backgroundColor: index === 0 ? "black" : sections[index - 1].color,
            duration: 0.5,
          });
        },
      });
    });
  }, []);

  return (
    <>
      <div className="spacer"></div>
      <div className="gallery" ref={containerRef}>
        <div className="overlay"></div>
        <div className="left">
          <div className="detailsWrapper">
            {sections.map((section, index) => (
              <div className={`details d${index + 1}`} key={index}>
                <div className="headline">
                  <h1 className="head-std-hm" style={{ color: "white" }}>
                    {section.title}
                  </h1>
                  <hr className="line-h" />
                </div>
                <div className="text">
                  <p>{section.text}</p>
                </div>
                <div className="sub-description-studio">
                  <p>{section.subDescription}</p>
                </div>
                <Link to={section.link} style={{ width: "max-content" }}>
                  <button className="std-knwm-btn">
                    <span className="button_top-std-knwm-btn" style={{ color: section.buttonColor }}>
                      Know more ...
                    </span>
                  </button>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="right">
          <div className="photos">
            <div className="photos-box">
              {images.map((image, index) => (
                <div className={`photo col${index + 1}`} key={index}>
                  <img src={image.src} alt={image.alt} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const sections = [
  {
    title: "Experience Design",
    text: "As experts in user-centric design, we develop intuitive and engaging experiences that captivate users. Our team meticulously crafts each element of the design process, from initial concept to final implementation, ensuring a cohesive and visually appealing interface. Our focus on seamless interaction ensures that every touchpoint is both functional and enjoyable, enhancing user satisfaction and loyalty. By employing the latest technologies and design principles, we distinguish ourselves in the creation of memorable digital experiences that not only meet but exceed user expectations.",
    subDescription: "User/Product Research, App Architecture & Design (Web, Mobile, Wearables), Accessibility. Branding & VFX/AR/VR, 3D....",
    link: "./experience-design",
    buttonColor: "rgb(249, 89, 51)",
  },
  {
    title: "Cloud Applications",
    text: "Specializing in cloud-native applications and the modernization of applications, we utilize the latest cloud platforms to develop scalable, reliable, and efficient solutions. Our team of experts is adept at leveraging tools like Kubernetes, Docker, and serverless architectures to ensure your applications run smoothly and efficiently. With extensive experience in cloud computing, we guarantee seamless integration and performance across all major cloud providers, including AWS, Azure, and Google Cloud. By staying at the forefront of technology, we deliver cutting-edge solutions tailored to your unique business needs, ensuring optimal performance and cost-effectiveness.",
    subDescription: "AWS, Azure, GCP clouds Java, .Net, Angular, React, NodeJS, Python...",
    link: "./cloud-applications",
    buttonColor: "rgb(100, 65, 160)",
  },
  {
    title: "Workforce Engagement",
    text: "Experts in SaaS application implementation and customization, we tailor software solutions to align with your unique business processes and workflows. Our bespoke approach ensures seamless integration and optimization, enhancing efficiency and perfectly aligning with your operational needs. By taking the time to understand your specific requirements, we are able to design and deploy solutions that not only meet but exceed your expectations. Our dedicated team provides ongoing support and continuous improvement, ensuring that your software evolves alongside your business.",
    subDescription: "Oracle Fusion Cloud, SAP, Salesforce, ServiceNow, Workday, Zoho...",
    link: "./workforce-engagement",
    buttonColor: "rgb(125, 200, 92)",
  },
  {
    title: "Web-3",
    text: "Leading the charge in Web3 development, we integrate AI/ML, IoT, and blockchain technologies to create innovative and transformative solutions. Our deep expertise enables us to drive the development of intelligent, connected, and decentralized applications that revolutionize various industries and enhance digital ecosystems. By leveraging cutting-edge technologies, we ensure that our solutions not only meet current demands but also anticipate future trends. Our commitment to excellence and forward-thinking approach helps businesses stay ahead in an increasingly digital and interconnected world.",
    subDescription: "Blockchain, AI/ML, IoT...",
    link: "./web-3",
    buttonColor: "rgb(0, 174, 207)",
  },
  {
    title: "Data Centric",
    text: "As leaders in data-centric services, we specialize in business intelligence, analytics, and integration. Our comprehensive solutions are designed to transform raw data into actionable insights, empowering organizations to make informed, strategic decisions. By leveraging advanced analytics and state-of-the-art integration techniques, we ensure seamless integration across your systems. This, in turn, drives operational efficiency, fosters innovation, and catalyzes sustainable business growth. Our commitment to excellence means we continuously stay ahead of industry trends, providing our clients with cutting-edge tools and methodologies to stay competitive in a rapidly evolving market.",
    subDescription: "Data Engg, Data Science, BI, Big Data, Integration (App, Data, Business Process)...",
    link: "./data-centric",
    buttonColor: "black",
  },
];

const images = [
  { src: XD, alt: "XD" },
  { src: CA, alt: "CA" },
  { src: WE, alt: "WE" },
  { src: W3, alt: "W3" },
  { src: DC, alt: "DC" },
];

export default Scroll;
