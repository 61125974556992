import React,{useEffect} from 'react';
import { motion } from 'framer-motion';
import "./Dc.css";
import DcHeroImg from "../../assets/dcHero.webp";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Link } from "react-router-dom";

function DcHero() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Animation variants
  const textVariants = {
    hidden: { opacity: 0, y: 30, scale: 0.9 },
    visible: { opacity: 1, y: 0, scale: 1, transition: { duration: 0.8, ease: "easeOut" } }
  };

  const staggerContainer = {
    hidden: {},
    visible: {
      transition: { staggerChildren: 0.2 }
    }
  };

  return (
    <div className="xd-hero-container">
      <div className="xd-hero-img-cc">
        <img src={DcHeroImg} alt="Xd hero" />

        <motion.div
          className="xd-hero-txt-cnt"
          variants={staggerContainer}
          initial="hidden"
          animate="visible"
        >
          <Link to="/studios" className="head-back-btn">
            <MdOutlineArrowBackIos className="back-icon-btn" />
            <motion.h5
              variants={textVariants}
            >
              DATA CENTRIC STUDIO
            </motion.h5>
          </Link>

          <motion.h3
            variants={textVariants}
          >
            Unlock the Power of Connected Systems & Data with Expert
            Integration & Analytics
          </motion.h3>
          
          <motion.p
            variants={textVariants}
          >
            In today's fast-paced digital world, the ability to seamlessly
            integrate your systems and harness the power of your data is a
            game-changer. At Supernal, we specialize in designing and
            implementing comprehensive data and integration solutions that
            empower your business to operate more efficiently, make informed
            decisions, and drive innovation.
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
}

export default DcHero;
