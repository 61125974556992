import React from "react";
import "./CoreThree.css";
import CoreImg from "../../../assets/coreValues.svg";

function CoreThree() {
  return (
    <div className="core-v-main-cnt">
      <div className="core-v-title-cnt">
        <h2>Core Values</h2>
      </div>
      <div className="core-v-items-cnt">
        <div className="core-text-item">
            <div className="core-item-cnt-text" style={{textAlign:"right"}}>
          <p>
            We are dedicated to delivering exceptional results and consistently
            meeting our clients’ needs. Our commitment drives us to go the extra
            mile in everything we do.
          </p>
          </div>
          <div className="core-item-cnt-text" style={{textAlign:"right"}}>
          <p>
            We value and respect our clients, partners, and team members. By
            fostering an environment of mutual respect, we encourage open
            communication and collaboration, leading to better solutions and
            stronger relationships.
          </p>
          </div>
        </div>

        <div className="core-img-item">
          <img src={CoreImg} alt="" />
        </div>

        <div className="core-text-item">
        <div className="core-item-cnt-text" style={{textAlign:"left"}} >
          <p>
            We take full responsibility for our work and outcomes. By fostering
            a culture of accountability, we ensure that every team member is
            invested in the success of our clients and our company.
          </p>
          </div>

          <div className="core-item-cnt-text" style={{textAlign:"left"}}>
          <p>
            We empower our team members and clients with the tools, knowledge,
            and support they need to succeed. By encouraging growth and
            innovation, we help unlock the full potential of everyone we work
            with.
          </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoreThree;
