import React from 'react'
import "./error404.css"
import { Link } from'react-router-dom'

function error404() {
  return (
    <div className='error404'>
    <div className="box404">
    <h2>404</h2>
    <p>Page Not Found</p>
    <Link to="/">    <button>
   HOME
</button> </Link>

 </div>
 </div>
  )
}

export default error404