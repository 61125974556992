import React,{useEffect} from "react";
import "./StudiosMain.css";
import StudioHero from "../../assets/studiosStockNew.png";
import StudioCards from "../../components/Cards/mainCards";

function Wepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="main-sd-hero-container">
        <div className="main-sd-hero-img-cc">
          <img src={StudioHero} alt="Xd hero" />
          <div className="main-sd-hero-txt-cnt">
            <h1 style={{ color: "#ffffff" }}>STUDIOS</h1>
            <p>
              Each studio is unique, where expertise goes beyond technical
              skills. It’s a fusion led by a Studio Partner, who brings not only
              technical prowess but also a deep understanding of business
              implications. This unique blend enables us to deliver solutions
              that exceed expectations.
            </p>
            {/* <button>Schedule a Consultation</button> */}
          </div>
        </div>
      </div>
      <div className="main-sd-cards-container">
        <StudioCards />
      </div>

    </>
  );
}

export default Wepage;
