// src/components/ConnectWithUs.js
import React from 'react';
import "./ConnectWithUs.css";
import ContactImg from "../../assets/ContactUs.webp";
import { Link } from 'react-router-dom';

function ConnectWithUs() {
  return (
    <div className='connect-with-us'>
      <div className='image-container-cc'>
        <img src={ContactImg} alt="Contact Us" />
        <div className='text-overlay-cc'>
          <h2>Your Vision. Our Expertise. Let's Build It Together.</h2>
          <p>We partner with businesses like yours to deliver cutting-edge technology solutions that drive growth and delight customers. Let's discuss your goals.</p>
          <Link to="/contact-us">
          <button>Schedule a Consultation</button>
          </Link>

        </div>
      </div>
    </div>
  );
}

export default ConnectWithUs;
