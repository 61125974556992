import React from 'react'
import Wordpress_Logo from "../../../assets/WordPress-logo-03.png"
import Magento_Logo from "../../../assets/Magento-03.png"

function AppPlatforms() {
  return (
    <>
    <div className='BackEndTech-Bg'>
      <div className='BET-Tech-left'>
        <img src={Wordpress_Logo} alt=""/>
      </div>
      <div className='BET-Tech-right'>
        <p>
        Unlocking stellar user experiences via responsive web solutions
        </p>
        <p>
        Many of the websites you come across are built on the ubiquitous
        Wordpress website development company and, at Supernal Infotech, we
        are fully equipped to deliver on your Wordpress site requirements. Our
        solution-oriented and feature-rich Wordpress sites are dynamic,
        customised and responsive, keeping audience engaged for hours.
        </p>
        <p>
        Our array of services on the Wordpress platform includes WordPress
        Theme Development, PSD to WordPress Conversion, Custom Plug-In
        Development, WordPress CMS Development, WordPress Installation,
        WordPress WooCommerce Development, Customised UI/UX, Upgrades and
        Migration as well as maintenance and support.
                
        </p>
      </div>
    </div>
{/* ------------------------------------------------------ */}
{/* ------------------------------------------------------ */}

<div className='BackEndTech-Bg-nodejs'>
      <div className='BET-Tech-right'>
        <p>
        The best solution for e-commerce sites
        </p>
        <p>
        Supernal Infotech’s suite of Magento ecommerce web development
        services is aimed towards building you the most intuitive website for your
        innovative product ideas. From customised user experiences to stable yet
        lite e-commerce systems, our Magento platform based developments will
        help you outmatch your peers effortlessly.
        </p>
        <p>
        We are frontrunners on the Magento platform and are bringing you a range
        of unique services including Magento eCommerce Website Development,
        Magento Customization, Magento Theme and Template Design, Magento
        Upgrade and Migration, Third-Party App Integration and Magento Support
        and Maintenance, in an effort to help you own the online-first economy we
        live in. 
       </p>
      </div>
      <div className='BET-Tech-left'>
        <img src={Magento_Logo} alt="nodejs logo"/>
      </div>

    </div>


    </>
  )
}

export default AppPlatforms