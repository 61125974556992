import React,{useEffect} from "react";
import "../XD/XD.css";
import CaHero from "../../assets/caHero.webp";
import OtherStudios from "../../components/Cards/mainCards";
import ConnectWithUss from "../../components/ConnectWithUs/ConnectWithUs";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { Link } from "react-router-dom";
import CaGif1 from "../../assets/StudiosGifs/CA-1.gif";
import CaGif2 from "../../assets/StudiosGifs/CA-2.gif";
import CaGif3 from "../../assets/StudiosGifs/CA-3 1.gif"
import { motion } from 'framer-motion';
import useScrollReveal from '../XD/Animation';

function CaPage() {

  const revealVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [ref1, controls1] = useScrollReveal({ triggerOnce: false, threshold: 0.1 });
  const [ref2, controls2] = useScrollReveal({ triggerOnce: false, threshold: 0.1 });
  const [ref3, controls3] = useScrollReveal({ triggerOnce: false, threshold: 0.1 });

  return (
    <>
     <div className="xd-hero-container">
        <div className="xd-hero-img-cc">
          <img src={CaHero} alt="Xd hero" />

          <div className="xd-hero-txt-cnt">
            <Link to="/studios" className="head-back-btn">
              <MdOutlineArrowBackIos className="back-icon-btn" />
              <h5>CLOUD NATIVE APPLICATIONS STUDIO</h5>
            </Link>

            <h3>
              Unlock the Power of Connected Systems & Data with Expert
              Integration & Analytics
            </h3>
            <p>
              Specialists in cloud-native applications, we leverage the latest
              cloud platforms to build scalable, reliable, and efficient
              solutions. Our expertise ensures seamless integration and
              performance across all major cloud providers, delivering
              cutting-edge technology tailored to your needs.
            </p>
          </div>
        </div>
      </div>

      <div className="card-st-container">
      <motion.div
          className="card-sd-item"
          ref={ref1}
          initial="hidden"
          animate={controls1}
          variants={revealVariants}
        >
          <div className="text-cnt-sd">
            <h2 style={{ color: "#6441A0" }}>Seamless Cloud Solutions </h2>
            <h3>WHAT WE DO</h3>
            <p>
              Security is vital, scalability is fundamental, and
              cost-effectiveness meets great performance, resulting in easy
              maintenance. From architecture design to polished user interfaces,
              our CA studio is a dependable partner in driving corporate success
              with Advanced Cloud services.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={CaGif1} alt="" />
          </div>
          </motion.div>



        <motion.div
          className="card-sd-item mb-reverse"
          ref={ref2}
          initial="hidden"
          animate={controls2}
          variants={revealVariants}
          // style={{ flexDirection: 'row-reverse' }}
        >
          <div className="text-cnt-sd">
            <h2 style={{ color: "#6441A0" }}>
            Technical Mastery and Full-stack Development 
            </h2>
            <h3>HOW WE DO IT</h3>
            <p>
              Our team combines cloud technology experience with full-stack
              development to ensure security, scalability, and
              cost-effectiveness. We rigorously plan infrastructures and provide
              polished user interfaces to provide seamless solutions.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={CaGif2} alt="" />
          </div>
          </motion.div>

          <motion.div
          className="card-sd-item"
          ref={ref3}
          initial="hidden"
          animate={controls3}
          variants={revealVariants}
        >
          <div className="text-cnt-sd">
            <h2 style={{ color: "#6441A0" }}>
            Efficient and Scalable Cloud Applications 
            </h2>
            <h3>FINAL PRODUCT</h3>
            <p>
              The end result is a cloud application that is safe, scalable,
              cost-effective, and easy to manage, all of which contribute to the
              success of your organisation.
            </p>
          </div>
          <div className="card-item-img-sd">
            <img src={CaGif3} alt="" />
          </div>
          </motion.div>
      </div>
      <OtherStudios />
      <ConnectWithUss />
    </>
  );
}

export default CaPage;
