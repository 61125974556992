import React, { useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import Xd from "./Pages/XD/XD";
import CA from "./Pages/CA/CA";
import We from "../src/Pages/WE/We";
import W3 from "../src/Pages/W3/w3";
import DC from "../src/Pages/DC/Dc";
import Navbar from "./components/Navbar/Navbar";
import Error404 from "./Pages/404page/error404";
import "./App.css";
import Footer from "../src/components/Footer/Footer";
import Popup from "./components/Popup/Popup";
import Banking from "./Pages/Industries/Banking/Banking";
import Technologies from "./Pages/Technologies/Technologies";
import ContactUs from "./components/Popup/ContactMobile";
import HealthFitness from "./Pages/Industries/HealthFitness/HealthFitness";
import MobileApps from "./Pages/Technologies/MobileApps/MobileApps";
import Frontend from "./Pages/Technologies/FrontEnd/FrontEnd";
import BackendOfferings from "./Pages/Technologies/BackendOfferings/BackendOfferings";
import Cloudservices from "./Pages/Technologies/CloudServices/CloudServices";
import AppPlatforms from "./Pages/Technologies/AppPlatforms/AppPlatforms";
import EcomQcom from "./Pages/Industries/EcomQcom/EcoQcom";
import Entertainment  from "./Pages/Industries/Entertainment /Entertainment ";
import Mobile from "./components/Navbar/MobileNav/Mobile";
import AboutUs from "./Pages/AboutUs/AboutUs";
import StudiosPage from "./Pages/StudiosMain/StudiosMain";
import IndustriesPages from "./Pages/IndustriesPages/IndustriesPages";
import Contact from "./Pages/ContactUs/Contact"
import Manufacturing from "./Pages/Industries/Manufacturing/Manufacturing";
import WebThreeArticles from "./components/HomeArticles/WebThreeArt/WebThreeArt";
import Cloudintegration from "./components/HomeArticles/Cloudintegration/Cloudintegration";
import ErpCrmSymphony from "./components/HomeArticles/ErpCrmSymphony/ErpCrmSymphony"
import MobileHome from "./components/CarouselHome/MobileHomePage/MobileHomePage"
function App() {

  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
  const showFooter = location.pathname !== "/"; // Conditionally show footer
  const addPadding = location.pathname !== "/"; // Conditionally add padding

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="app">
 
        <Navbar />

      <div className={addPadding ? "app-top-padding" : ""}>  
  
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/experience-design" element={<Xd />} />
          <Route path="/cloud-applications" element={<CA />} />
          <Route path="/workforce-engagement" element={<We />} />
          <Route path="/web-3" element={<W3 />} />
          <Route path="/data-centric" element={<DC />} />

          {/* Nested routes for indusstries*/}
          <Route path="/industries" element={<IndustriesPages />}>
            <Route index element={<Banking />} />
            <Route path="banking&finance" element={<Banking />} />
            <Route path="health&fitness" element={<HealthFitness />} />

            <Route path="e-commerce&quick-commerce" element={<EcomQcom />} />
            <Route path="entertainment&sports" element={<Entertainment />} />
            <Route path="manufacturing" element={<Manufacturing />} />
          </Route>
             {/* Nested routes for indusstries end*/}



           <Route path="/erp-crm-symphony" exact element={<ErpCrmSymphony />} />
          <Route path="/testing" exact element={<WebThreeArticles />} />
          <Route path="/banking&finance" exact element={<Banking />} />
          <Route path="/contact-us" element={<Contact/>}/>
          <Route path="/technologies" exact element={<Technologies />} />
          <Route path="/contact-us" exact element={<ContactUs />} />
          <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/studios" exact element={<StudiosPage />} />
          <Route path="/backend-offerings" exact element={<BackendOfferings />} />
          <Route path="/app-platforms" exact element={<AppPlatforms />} />
          <Route path="/entertainment&sports" exact element={<Entertainment />} />
          <Route path="/health&fitness" exact element={<HealthFitness />} />
          <Route path="/mobile-apps" exact element={<MobileApps />} />
          <Route path="/frontend-solutions" exact element={<Frontend />} />
          <Route path="/cloud-services" exact element={<Cloudservices />} />
          <Route path="/manufacturing" element={<Manufacturing />} />
          <Route path="/e-commerce&quick-commerce" element={<EcomQcom />} />
          <Route path="/cloud-integration" element={<Cloudintegration />} />
          <Route path="/mobile" element={<MobileHome/>} />
        
          <Route path="*" element={<Error404 />} />
        </Routes>
        </div>
   
      {showFooter && <Footer />}
      {/* <Footer /> */}
      <Popup show={showPopup} handleClose={togglePopup} />
    </div>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;

