import React, { useEffect } from "react";
import "./HealthFitness.css";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import image1 from "../../../assets/IndustriesAssets/Gamified.webp";
import image2 from "../../../assets/IndustriesAssets/Meditation.webp";
import image3 from "../../../assets/IndustriesAssets/Powered.webp";
import image4 from "../../../assets/IndustriesAssets/Communitybased.webp";
import ConnectWithUs from "../../../components/ConnectWithUs/ConnectWithUs"

gsap.registerPlugin(ScrollTrigger);
function Health() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    const items = document.querySelectorAll(".industry__item");

    items.forEach((item) => {
      const itemTitle = item.querySelector(".industry__item__title");
      const itemImg = item.querySelector(".industry__item img");
      const headings = itemTitle.querySelectorAll("h1, h2, p");

      const itemTL = gsap.timeline({
        scrollTrigger: {
          trigger: item,
          start: "top 100%",
          end: "bottom 55%",
          scrub: 3,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          // markers: true,
        },
      });

      itemTL
        .fromTo(
          itemTitle,
          { opacity: 0, y: "20%" },
          { opacity: 1, y: "0%", ease: "power2.inOut", duration: 1.5 },
          0
        )
        .fromTo(
          headings,
          { opacity: 0, y: "10%" },
          { opacity: 1, y: "0%", ease: "power2.inOut", stagger: 0.3, duration: 1 },
          0
        )
        .fromTo(
          itemImg,
          { scale: 0, x: "0vw", y: "50vh", rotate: -30 },
          {
            scale: 1.2,
            x: "-10vw",
            y: "0vh",
            rotate: 0,
            ease: "power2.inOut",
            duration: 15,
          },
          "+=15"
        )
        .to(
          itemImg,
          {
            scale: 0,
            x: "50vw",
            y: "-50vh",
            rotate: 30,
            ease: "power2.inOut",
            duration: 15,
          },
          "+=15"
        );
    });
  }, []);

  return (
    <>
      <div className="health-fitness-img">
        <div className="health-fitness-styling">
          <h1>Fitness & Health</h1>
          <p>
            Supernal Infotech, capitalising on a legacy of excellence, enables
            fitness applications of all sizes, globally, to transform their
            fitness objectives into seamless reality. Supernal Infotech provides
            industry insights, technology, and innovation to enable long-term
            growth partnerships.
          </p>
        </div>
      </div>

      {/* -------------------------- */}
    

      <div className="industries__container">
        <div className="industries__list">
          <div className="industry__item">
            <div className="industry__item__title">
              <h1>Gamified Fitness AI Apps</h1>

              <p>
                Supernal Info Gamification AI fitness app makes more enjoyable,
                engaging, and positive. Gamification apps involve applying game
                mechanics to non-game situations. Supernal Info Gamification AI
                fitness app ultimate goal is to use a data-driven approach to
                drive, engage, and motivate the user, among others. Supernal
                Info Gamification AI fitness app combines imagination and keeps
                the user's interest alive in the exercise routine.
              </p>
            </div>
            <img src={image1} alt="Item 1" />
          </div>

          <div className="industry__item">
            <div className="industry__item__title">
              <h1>Meditation AI Apps </h1>

              <p>
                Supernal Info meditation AI app features adjust the plan based
                on data provided and calming music and environment to meditate.
              </p>
            </div>
            <img src={image2} alt="Item 1" />
          </div>

          <div className="industry__item">
            <div className="industry__item__title">
              <h1>AI-Powered Workout Apps</h1>
             
              <p>
              Supernal Info AI fitness app utilizes artificial intelligence to personalize workout plans according to data provided by the clients. This data usually consists of an individual's fitness level, goals, equipment availability, and any other preferences. Supernal Info AI Fitness apps adjust your workouts plan based on data we gather from clients' performance and feedback.
              </p>
            </div>
            <img src={image3} alt="Item 1" />
          </div>

          <div className="industry__item">
            <div className="industry__item__title">
              <h1>Community-Based AI Fitness Apps </h1>

              <p>
              Supernal Info AI Community-based fitness apps allow like-minded people to form groups and communities and exercise and workout together. Also, they share motivations, their fitness goal achievements, and appreciation, using the app.


              </p>
            </div>
            <img src={image4} alt="Item 1" />
          </div>
        </div>
      </div>
      <ConnectWithUs/>
    </>
  );
}

export default Health;
