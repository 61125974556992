import React from 'react'
import Aws_logo from "../../../assets/Aws-logo-03.png"
import Azur_logo from "../../../assets/Azure-logo-03.png"

function CloudServices() {
  return (
    <>   
    <div className='Mobile-App-Tech-Bg'>
     <div className='MAT-Rnative-Img'>
       <img src={Aws_logo} alt=""/>

     </div>
     <div className='MAT-Rnative-Text'>
  
       <p>
       Capturing the potential of secure and scalable cloud solutions
       </p>  
       <p>
        Supernal Infotech has enabled many a client to embark on the cloud voyage
        through AWS (Amazon Web Services) and our focused solutions consist of
        app development and migration, app deployment and optimization, BI &amp;
        Analytics, and managed services, along with effective cloud consulting to
        decode the best mode for your app.
        </p> 
        <p>
        You can trust us when we say that we are one of the most trusted AWS
        partners in India and our solutions will unlock tremendous capacity and
        flexibility for your product. You will also be able to minimise usual pain
        points thanks to our AWS-certified team and customised strategy.

       </p>
     </div>

   </div>

   {/* -------------------------------------- */}

   <div className='Mobile-App-Tech-Flutter-Bg'>

     <div className='MAT-Flutter-Text'>
       <p>
        End-to-end cloud transformation for your business
        </p>
        <p>
        Supernal Infotech is a qualified Microsoft Partner capable of bringing you
        the best solutions through the Azure cloud platform. With years of
        experience in the cloud management sector, and numerous clients who
        have benefitted from our tailored solutions, we are the best partner for
        your Azure cloud requirements.
        </p>
        <p>
        Our one-stop shop solutions include Azure Consulting Services, Azure
        Application Development, Azure Migration Services, Azure Managed
        Services and Data and Analytics and, with our consistent support and
        strategic advantage, your business will never lag behind in new age cloud
        adoption.

       </p>
     </div>

     <div className='MAT-Flutter-Img'>
       <img src={Azur_logo} alt="flutter-logo"/>

     </div>

   </div>



   </>
   
  )
}

export default CloudServices