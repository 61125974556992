import React from "react";
import ContactImg from "../../../assets/ContactUs.webp";
import { Link } from "react-router-dom";
 
function EducationConnectus() {
  return (
    <div className="connect-with-us">
      <div className="image-container-cc">
        <img src={ContactImg} alt="Contact Us" />
        <div className="text-overlay-cc">
          <h2>Your Partner in Innovation</h2>
          <p>
            At Supernal Infotech, we’re not just your trusted partner in the
            entertainment and sports industry—we're your secret weapon! With our
            knack for mobile apps, custom solutions, and advanced analytics, we
            help you wow your fans and audiences like never before. Let’s turn
            your visions into unforgettable experiences!
          </p>
        
          <p>
            Partner with us to revolutionize your entertainment and sports
            operations with our cutting-edge technology solutions! Let’s create
            something amazing together!
          </p>
          <Link to="/contact-us">
            <button>Contact Us Today</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
 
export default EducationConnectus;